import { connect } from 'react-redux';
import { navigationActions, recipeActions } from '../../store/actions';

import Recipe from '../../components/Recipe/Recipe';


const mapStateToProps = ( state,ownProps ) => ({
    redirectTo: state.navigation.redirectTo,
    slug: ownProps.match.params.slug,
    isPublic: ownProps.match.params.isPublic,
    userId: state.user.user.id,
    loader: state.recipes.loader,
    recipe: state.recipes.recipe,
    tags: state.recipes.details.tags,
    units: state.recipes.details.units,
    status: state.recipes.details.status,
    ingredients: state.recipes.details.ingredients,
    texts: state.navigation.texts.recipe,
});

const mapDispatchToProps = (dispatch) => ({
    redirected: () => {
        dispatch(navigationActions.redirected());
    },
    getOneRecipesBySlug: ( payload ) => {
        dispatch(recipeActions.getOneRecipesBySlug( payload ));
    },
    editRecipeSubmit: ( payload, payload2, payload3, payload4 ) => {
        dispatch(recipeActions.editRecipeSubmit( payload, payload2, payload3, payload4 ) );
    },
    clearUserMessage: () => {
        dispatch(navigationActions.clearUserMessage() );
    },
    deleteRecipeSubmit: ( payload ) => {
        dispatch(recipeActions.deleteRecipeSubmit( payload ) );
    },
});



export default connect(mapStateToProps, mapDispatchToProps)(Recipe);

