import { connect } from 'react-redux';
import { filesActions } from '../../store/actions';

import PicturesList from '../../components/PicturesList/PicturesList';

const mapStateToProps = ( state ) => ({
    recipe: state.recipes.recipe,
    texts: state.navigation.texts.PicturesList,
});

const mapDispatchToProps = (dispatch) => ({
    uploadFile: ( payload, payload2 ) => {
        dispatch(filesActions.uploadFile( payload, payload2 ));
    },

    deleteFile: ( payload, payload2 ) => {
        dispatch(filesActions.deleteFile( payload, payload2 ));
    },

});

export default connect(mapStateToProps, mapDispatchToProps)(PicturesList);

