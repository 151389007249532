import { connect } from 'react-redux';
import { navigationActions } from '../../store/actions';
import Footer from '../../components/Footer/Footer';


const mapStateToProps = (state, ownProps) => ({
  creator: state.navigation.creator,
  texts: state.navigation.texts.footer,
});

const mapDispatchToProps = (dispatch) => ({});



export default connect(mapStateToProps, mapDispatchToProps)(Footer);