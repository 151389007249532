export const LOGIN_SUBMIT = 'LOGIN_SUBMIT';
export const LOGIN_SUBMIT_SUCCESS = 'LOGIN_SUBMIT_SUCCESS';
export const LOGIN_SUBMIT_FAIL = 'LOGIN_SUBMIT_FAIL';

export const LOGOUT = 'LOGOUT';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAIL = 'LOGOUT_FAIL';

export const EDIT_USER_SUBMIT = 'EDIT_USER_SUBMIT';
export const EDIT_USER_SUBMIT_SUCCESS = 'EDIT_USER_SUBMIT_SUCCESS';
export const EDIT_USER_SUBMIT_FAIL = 'EDIT_USER_SUBMIT_FAIL';

export const ASK_PASSWORD_RESET = 'ASK_PASSWORD_RESET';
export const ASK_PASSWORD_RESET_SUCCESS = 'ASK_PASSWORD_RESET_SUCCESS';
export const ASK_PASSWORD_RESET_FAIL = 'ASK_PASSWORD_RESET_FAIL';

export const PASSWORD_RESET_SUBMIT = 'PASSWORD_RESET_SUBMIT';
export const PASSWORD_RESET_SUBMIT_SUCCESS = 'PASSWORD_RESET_SUBMIT_SUCCESS';
export const PASSWORD_RESET_SUBMIT_FAIL = 'PASSWORD_RESET_SUBMIT_FAIL';

export const ASK_REGISTER_SUBMIT = 'ASK_REGISTER_SUBMIT';
export const ASK_REGISTER_SUBMIT_SUCCESS = 'ASK_REGISTER_SUBMIT_SUCCESS';
export const ASK_REGISTER_SUBMIT_FAIL = 'ASK_REGISTER_SUBMIT_FAIL';

export const CONFIRME_MAIL_SUBMIT = 'CONFIRME_MAIL_SUBMIT';
export const CONFIRME_MAIL_SUBMIT_SUCCESS = 'CONFIRME_MAIL_SUBMIT_SUCCESS';
export const CONFIRME_MAIL_SUBMIT_FAIL = 'CONFIRME_MAIL_SUBMIT_FAIL';

export const DELETE_USER_SUBMIT = 'DELETE_USER_SUBMIT';
export const DELETE_USER_SUBMIT_SUCCESS = 'DELETE_USER_SUBMIT_SUCCESS';
export const DELETE_USER_SUBMIT_FAIL = 'DELETE_USER_SUBMIT_FAIL';


export const loginSubmit = (payload) => ({
  type: LOGIN_SUBMIT,
  payload,
});

export const loginSubmitSuccess = (payload) => ({
  type: LOGIN_SUBMIT_SUCCESS,
  payload,
});

export const loginSubmitFail = (payload) => ({
  type: LOGIN_SUBMIT_FAIL,
  payload,
});

export const logout = () => ({
  type: LOGOUT,
});

export const logoutSuccess = () => ({
  type: LOGOUT_SUCCESS,
});

export const logoutFail = () => ({
  type: LOGOUT_FAIL,
});

export const editUserSubmit = (payload) => ({
  type: EDIT_USER_SUBMIT,
  payload,
});

export const editUserSubmitSuccess = (payload) => ({
  type: EDIT_USER_SUBMIT_SUCCESS,
  payload,
});

export const editUserSubmitFail = (payload) => ({
  type: EDIT_USER_SUBMIT_FAIL,
  payload,
});

export const askPasswordReset = (payload) => ({
  type: ASK_PASSWORD_RESET,
  payload,
});

export const askPasswordResetSuccess = (payload) => ({
  type: ASK_PASSWORD_RESET_SUCCESS,
  payload,
});

export const askPasswordResetFail = (payload) => ({
  type: ASK_PASSWORD_RESET_FAIL,
  payload,
});

export const passwordResetSubmit = (payload) => ({
  type: PASSWORD_RESET_SUBMIT,
  payload,
});

export const passwordResetSubmitSuccess = (payload) => ({
  type: PASSWORD_RESET_SUBMIT_SUCCESS,
  payload,
});

export const passwordResetSubmitFail = (payload) => ({
  type: PASSWORD_RESET_SUBMIT_FAIL,
  payload,
});

export const askRegisterSubmit = (payload) => ({
  type: ASK_REGISTER_SUBMIT,
  payload,
});

export const askRegisterSubmitSuccess = (payload) => ({
  type: ASK_REGISTER_SUBMIT_SUCCESS,
  payload,
});

export const askRegisterSubmitFail = (payload) => ({
  type: ASK_REGISTER_SUBMIT_FAIL,
  payload,
});

export const confirmeMailSubmit = (payload) => ({
  type: CONFIRME_MAIL_SUBMIT,
  payload,
});

export const confirmeMailSubmitSuccess = (payload) => ({
  type: CONFIRME_MAIL_SUBMIT_SUCCESS,
  payload,
});

export const confirmeMailSubmitFail = (payload) => ({
  type: CONFIRME_MAIL_SUBMIT_FAIL,
  payload,
});

export const deleteUserSubmit = (payload) => ({
  type: DELETE_USER_SUBMIT,
  payload,
});

export const deleteUserSubmitSuccess = (payload) => ({
  type: DELETE_USER_SUBMIT_SUCCESS,
  payload,
});

export const deleteUserSubmitFail = (payload) => ({
  type: DELETE_USER_SUBMIT_FAIL,
  payload,
});