import { connect } from 'react-redux';
import { navigationActions } from '../../store/actions';

import NotFound from '../../components/NotFound/NotFound';


const mapStateToProps = ( state ) => ({
  text404: state.navigation.text404,
  redirectTo: state.navigation.redirectTo,
});

const mapDispatchToProps = (dispatch) => ({
  redirected: () => {
    dispatch(navigationActions.redirected());
  },
});



export default connect(mapStateToProps, mapDispatchToProps)(NotFound);

