/**
 * Import Modules
 */
import React from 'react'
//import PropTypes from 'prop-types';
import { useHistory } from "react-router-dom";

/**
 * import components
 */


/**
 * Import Locals
 */
import './AppTitle.scss'


const AppTitle = () => {
  const history = useHistory();

  const handleClickHome = _ => {
    history.push("/");
  }

  return (
      <>
          <h1
            className={"AppTitle"}
            onClick={ handleClickHome }
          >My family recipes</h1>

      </>

  );
};

AppTitle.propTypes = {};

export default AppTitle;