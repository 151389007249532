import { connect } from 'react-redux';
import { userActions } from '../../store/actions';

import AskPasswordReset from '../../components/AskPasswordReset/AskPasswordReset';


const mapStateToProps = ( state ) => ({
    message: state.user.message,
    status: state.user.status,
    loader: state.user.loader,
    texts: state.navigation.texts.askPasswordReset,
});

const mapDispatchToProps = (dispatch) => ({
    askPasswordReset: ( payload ) => {
        dispatch(userActions.askPasswordReset( payload ));
    },
});



export default connect(mapStateToProps, mapDispatchToProps)(AskPasswordReset);

