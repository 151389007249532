import { connect } from 'react-redux';
import {navigationActions, recipeActions, userActions} from '../../store/actions';
import App from '../../App'


const mapStateToProps = ( state ) => ({
    redirectTo: state.navigation.redirectTo,
    isLogged: state.user.isLogged,
    userId: state.user.user.id || state.user.user.oauth_id,
    texts: state.navigation.texts.app,
});

const mapDispatchToProps = (dispatch) => ({
    redirected: () => {
        dispatch(navigationActions.redirected());
    },
    getTagsIngredientsUnitsStatus: () => {
        dispatch(recipeActions.getTagsIngredientsUnitsStatus());
    },
    loginSubmitSuccess: ( payload ) => {
        dispatch(userActions.loginSubmitSuccess( payload ));
    },
    getPublicRecipes: () => {
        dispatch(recipeActions.getPublicRecipes());
    },
    getOwnRecipes: ( payload ) => {
        dispatch(recipeActions.getOwnRecipes( payload ));
    },
    setLanguage: ( payload ) => {
        dispatch(navigationActions.setLanguage( payload ));
    },
});




export default connect(mapStateToProps, mapDispatchToProps)(App);