/**
 * Import modules
 */
import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from "react-tooltip";

/**
 * Import locals
 */
import './Footer.scss'


const Footer =
    ({
         creator,
         texts,
     }) => {
        return (
            <div className='Footer'>
                <div
                    className="footer-container"
                ><div className={'favicon-attribute'}
                >
                    favIcon made by
                    <a href="https://www.flaticon.com/authors/ultimatearm" title="ultimatearm"
                    >
                        {' ultimatearm '}
                    </a>
                    from
                    <a href="https://www.flaticon.com/" title="Flaticon"
                    >
                        {' www.flaticon.com '}
                    </a>
                </div>
                    <div
                        className="creator"
                        data-tip={'My Github\'s link'}
                    >
                        { texts.creator }
                        <a
                            className="github-profile"
                            href="https://github.com/olivier-belleval"
                        >
                            {creator}
                        </a>


                    </div>
                    <ReactTooltip/>
                </div>
            </div>
        );
    };

Footer.propTypes = {
    creator: PropTypes.string,
    texts: PropTypes.shape({
        creator: PropTypes.string.isRequired,
    }).isRequired,
};

export default Footer;