
import { filesActions } from '../actions';
import { toast } from 'react-toastify';

import axios from 'axios';


import {
    uploadFile_success,
    uploadFile_fail,
    set_edit_mode,
    deleteFile_success,
    deleteFile_fail
} from "../actions/filesActions";
import {getOneRecipesBySlug} from "../actions/recipeActions";

import utility from "../../utility";
import {languages} from "../../text.json";

const utils = {
    url: utility.apiUrl,
    requestUrl: "",
    language: 'en',
};
export const filesMiddleware = (store) => (next) => async (action) => {
    next(action);
    switch (action.type) {
        case filesActions.UPLOAD_FILE:
            utils.requestUrl = `${utils.url}file/upload`
            axios.post(utils.requestUrl,action.payload,{
                withCredentials: true,
            })
                .then(async (res) => {
                    store.dispatch(uploadFile_success({...res.data, status: res.status}));
                    store.dispatch(set_edit_mode());
                })
                .catch((err) => {
                    console.error(err.response);
                    store.dispatch(uploadFile_fail({
                        ...err.response.data,
                        status: err.response.status
                    }));
                    toast.error(err.response);
                });
            break

        case filesActions.DELETE_FILE:
            utils.requestUrl = `${utils.url}file/delete`
            axios({
                method: "delete",
                url: utils.requestUrl,
                data: { ...action.payload },
                withCredentials: true,
            })
                .then(async (res) => {
                    store.dispatch(deleteFile_success({...res.data, status: res.status}));
                    store.dispatch(getOneRecipesBySlug({slug:res.data.recipeSlug}));
                })
                .catch((err) => {
                    console.error(err.response);
                    store.dispatch(deleteFile_fail({
                        ...err.response.data,
                        status: err.response.status
                    }));
                    toast.error(err.response);
                });
            break

        default:
            return;
    }
};