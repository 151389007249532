import { connect } from 'react-redux';
import { navigationActions, recipeActions } from '../../store/actions';

import SearchBar from '../../components/SearchBar/SearchBar';


const mapStateToProps = ( state ) => ({
    recipes: state.recipes.recipes,
    tags: state.recipes.details.tags,
    texts: state.navigation.texts.searchBar,
});

const mapDispatchToProps = (dispatch) => ({
    redirected: () => {
    dispatch(navigationActions.redirected());
    },
    filterRecipes: ( payload ) => {
        dispatch(recipeActions.filterRecipes( payload ));
    },
});



export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);

