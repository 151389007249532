/**
 * Import modules
 */
import axios from 'axios';
import { toast } from 'react-toastify';


/**
 * Import locals
 */
import {languages} from '../../text.json'
import utility from '../../utility'

/**
 * Import Actions
 */
import {
    LOGIN_SUBMIT,
    EDIT_USER_SUBMIT,
    ASK_PASSWORD_RESET,
    PASSWORD_RESET_SUBMIT,
    LOGOUT,
    ASK_REGISTER_SUBMIT,
    CONFIRME_MAIL_SUBMIT,
    DELETE_USER_SUBMIT,
    loginSubmitSuccess,
    loginSubmitFail,
    editUserSubmitSuccess,
    editUserSubmitFail,
    askPasswordResetSuccess,
    askPasswordResetFail,
    passwordResetSubmitSuccess,
    passwordResetSubmitFail,
    logoutSuccess,
    logoutFail,
    confirmeMailSubmitSuccess,
    confirmeMailSubmitFail,
    deleteUserSubmitSuccess,
    deleteUserSubmitFail,
} from "../actions/userActions";
import {
    redirect,
} from "../actions/navigationActions";
import {
    getOwnRecipes,
    getPublicRecipes
} from "../actions/recipeActions"


export const userMiddleware = (store) => (next) => async (action) => {
    next(action);

    const utils = {
        url: utility.apiUrl,
        language: 'en',
    };


    switch (action.type) {
        case LOGIN_SUBMIT:
            utils.language = store.getState().navigation.language
            axios({
                method: "post",
                url: `${utils.url}user/login/`,
                data: {...action.payload},
                withCredentials: true,
            })
                .then(async (res) => {
                    store.dispatch(loginSubmitSuccess({...res.data, status: res.status}));
                    store.dispatch(getOwnRecipes(res.data.user.id))
                    toast.success(`${languages[utils.language].messages.loginSuccess} ${res.data.user.username}`);
                })
                .catch((err) => {
                    console.error(err.response);
                    toast.error(languages[utils.language].messages.loginError);
                    store.dispatch(loginSubmitFail({...err.response.data, status: err.response.status}));
                });
            break

        case LOGOUT:
            utils.language = store.getState().navigation.language
            axios({
                method: "delete",
                url: `${utils.url}user/logout`,
                withCredentials: true,
            })
                .then(async (res) => {
                    store.dispatch(logoutSuccess({...res.data, status: res.status}));
                    store.dispatch(redirect('/'))
                    store.dispatch(getPublicRecipes())
                    window.location.reload();
                    toast.success(languages[utils.language].messages.logoutSuccess);
                })
                .catch((err) => {
                    console.error(err.response);
                    store.dispatch(logoutFail({...err.response.data, status: err.response.status}));
                });
            break

        case EDIT_USER_SUBMIT:
            utils.language = store.getState().navigation.language
            axios({
                method: "put",
                url: `${utils.url}admin/userEdit/${action.payload.id}`,
                data: {...action.payload},
                withCredentials: true,
            })
                .then(async (res) => {
                    store.dispatch(editUserSubmitSuccess({...res.data, status: res.status}));
                    toast.success(languages[utils.language].messages.editUserSuccess);
                })
                .catch((err) => {
                    console.error(err.response);
                    store.dispatch(editUserSubmitFail({...err.response.data, status: err.response.status}));
                    toast.error(languages[utils.language].message.editUserError);
                });
            break

        case ASK_PASSWORD_RESET:
            utils.language = store.getState().navigation.language
            axios({
                method: "post",
                url: `${utils.url}admin/createreseturl`,
                data: {...action.payload},
                withCredentials: true,
            })
                .then(async (res) => {
                    store.dispatch(askPasswordResetSuccess({...res.data, status: res.status}));
                    toast.success(`${languages[utils.language].messages.askResetPasswordSuccess}${action.payload.email}`);
                })
                .catch((err) => {
                    console.error(err.response);
                    store.dispatch(askPasswordResetFail({...err.response.data, status: err.response.status}));
                });
            break

        case PASSWORD_RESET_SUBMIT:
            const {userId, token, password, confirmePassword} = action.payload
            axios({
                method: "post",
                url: `${utils.url}admin/resetpassword/${userId}/${token}`,
                data: {password, confirmePassword},
                withCredentials: true,
            })
                .then(async (res) => {
                    store.dispatch(passwordResetSubmitSuccess({...res.data, status: res.status}));
                })
                .catch((err) => {
                    console.error(err.response);
                    let message
                    switch (err.response.data.message.message) {
                        case 'jwt expired':
                            message = 'La clef a expirée, merci de refaire une demande'
                            break

                        case 'invalid signature':
                            message = 'La clef est invalide, merci de refaire une demande'
                            break

                        default :
                            message = err.response.data.message.message
                    }


                    store.dispatch(passwordResetSubmitFail({
                        ...err.response.data,
                        message,
                        status: err.response.status
                    }));
                });
            break

        case ASK_REGISTER_SUBMIT:
            utils.language = store.getState().navigation.language
            axios({
                method: "post",
                url: `${utils.url}user/askRegister/`,
                data: {...action.payload},
                withCredentials: true,
            })
                .then(async (res) => {
                    store.dispatch(askPasswordResetSuccess({...res.data, status: res.status}));
                    toast.success(`${languages[utils.language].messages.askRegisterSuccess}${action.payload.email}`);
                })
                .catch((err) => {
                    console.error(err.response);
                    switch (err.response.status) {
                        case 409:
                            toast.error(languages[utils.language].messages.askRegisterError409);
                            break

                        default :
                            toast.error(languages[utils.language].messages.askRegisterError);
                    }
                    store.dispatch(askPasswordResetFail({...err.response.data, status: err.response.status}));

                });
            break

        case CONFIRME_MAIL_SUBMIT:
            utils.language = store.getState().navigation.language
            axios({
                method: "get",
                url: `${utils.url}user/confirmeMail/${action.payload}`,
                withCredentials: true,
            })
                .then(async (res) => {
                    store.dispatch(confirmeMailSubmitSuccess({...res.data, status: res.status}));
                    toast.success(`${languages[utils.language].messages.confirmeEmailSuccess}`);
                })
                .catch((err) => {
                    console.error(err.response);
                    let message
                    let showLogin
                    switch (err.response.data.message) {
                        case 'error: la valeur d\'une clé dupliquée rompt la contrainte unique « user_username_key »':
                            message = 'User already registered'
                            toast.error(languages[utils.language].messages.confirmeEmailDuplicateError);
                            showLogin = true
                            break

                        case 'invalid signature':
                            message = 'La clef est invalide, merci de refaire une demande'
                            toast.error(languages[utils.language].messages.confirmeEmailKeyError);
                            showLogin = false
                            break

                        default :
                            toast.error(languages[utils.language].messages.confirmeEmailKeyError);
                            message = err.response.data.message.message
                    }
                    store.dispatch(confirmeMailSubmitFail({
                        ...err.response.data,
                        message,
                        status: err.response.status,
                        showLogin
                    }));
                });
            break

        case DELETE_USER_SUBMIT:
            axios({
                method: "delete",
                url: `${utils.url}admin/account/${action.payload}/delete`,
                withCredentials: true,
            })
                .then(async (res) => {
                    store.dispatch(deleteUserSubmitSuccess({...res.data, status: res.status}));
                    store.dispatch(logoutSuccess({...res.data, status: res.status}))
                })
                .catch((err) => {
                    console.error(err.response);
                    let message = err.response.data.message.message

                    store.dispatch(deleteUserSubmitFail({...err.response.data, message, status: err.response.status}));
                });
            break


        default:
            return
    }

};