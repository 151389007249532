import { connect } from 'react-redux';
import { navigationActions, userActions } from '../../store/actions';

import PasswordReset from '../../components/PasswordReset/PasswordReset';


const mapStateToProps = ( state, ownProps ) => {

    const token = ownProps.match.params.token;
    const userId = parseInt(ownProps.match.params.userId);

    return {
        token,
        userId,
        message: state.user.message,
        status: state.user.status,
        loader: state.user.loader,
    }
};

const mapDispatchToProps = (dispatch) => ({
    passwordResetSubmit: ( payload ) => {
        dispatch(userActions.passwordResetSubmit( payload ));
    },

    clearUserMessage: () => {
        dispatch(navigationActions.clearUserMessage());
    },
});



export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset);

