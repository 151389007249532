import { connect } from 'react-redux';
import { navigationActions, userActions } from '../../store/actions';

import ConfirmeEmail from '../../components/ConfirmeEmail/ConfirmeEmail';


const mapStateToProps = ( state, ownProps ) => {

    const token = ownProps.match.params.token;

    return {
        token,
        redirectTo: state.navigation.redirectTo,
        message: state.user.message,
        loader: state.user.loader,
        texts: state.navigation.texts.confirmeEmail,
        showLogin: state.user.showLogin,
    }
};

const mapDispatchToProps = (dispatch) => ({
    redirected: () => {
        dispatch(navigationActions.redirected());
    },

    confirmeMailSubmit: ( payload ) => {
        dispatch(userActions.confirmeMailSubmit( payload ));
    },

    clearUserMessage: () => {
        dispatch(navigationActions.clearUserMessage());
    },
});



export default connect(mapStateToProps, mapDispatchToProps)(ConfirmeEmail);

