import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {Link, Redirect} from 'react-router-dom';

import './Welcome.scss'


const Welcome = ({
  redirectTo,
  isLogged,
  texts,

//FUNCTION
  redirected
}) => {
  useEffect( () => {
    if ( redirectTo === '/') {
      redirected()
    }
  },[redirectTo])

  return (
    <div className='Welcome h-100'>
      {
        isLogged ? <Redirect to={"/recipes"} /> : <></>
      }
      <h1 className={"welcome-title"}>
        {
          texts.welcomeTitle
        }
      </h1>

      <main className="welcome-content d-flex flex-column justify-content-lg-evenly">
        <div className="welcome-message">
          <p className={'welcome-why-myfamilyrecipe'}>
            {
              texts.whyMyFamilyRecipe
            }
          </p>
          <br/>
          {
            texts.pathToPublicRecipes
          }
          <Link
            to={ `/recipes` }
            key={ `recipes-link` }
            className="recipes-link"
          >
            {
              texts.linkToPublicRecipes
            }
          </Link>
        </div>

        <div className="login-message">
          {
            texts.pathToRegister
          }
          <Link
            to={ `/register` }
            key={ `register-link` }
            className="register-link"
          >
            { texts.register}
          </Link>
          {
            ` or `
          }
          <Link
            to={ `/login` }
            key={ `login-link` }
            className="login-link"
          >
            { texts.login }
          </Link>
        </div>

      </main>
    </div>
  );
};

Welcome.propTypes = {
  text404: PropTypes.arrayOf(PropTypes.string),
  redirectTo: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]),
  isLogged: PropTypes.bool.isRequired,
  texts: PropTypes.shape({
    welcomeTitle: PropTypes.string.isRequired,
    whyMyFamilyRecipe: PropTypes.string.isRequired,
    pathToPublicRecipes: PropTypes.string.isRequired,
    linkToPublicRecipes: PropTypes.string.isRequired,
    pathToRegister: PropTypes.string.isRequired,
    register: PropTypes.string.isRequired,
    login: PropTypes.string.isRequired,
  }),

  redirected: PropTypes.func
};

export default Welcome;