/**
 * Import Modules
 */
import React, {useEffect} from 'react'
import PropTypes from 'prop-types';
import { Card, Spinner } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import { Link, Redirect } from "react-router-dom";

/**
 * import components
 */


/**
 * Import Locals
 */
import './RecipesList.scss'
import ReactTooltip from "react-tooltip";

const RecipesList = ({
    recipes,
    loader,
    isLogged,
    redirectTo,
    userId,
    texts,

    //func
    getPublicRecipes,
    getOwnRecipes,
    redirected
}) => {

    useEffect( () => {
        if ( redirectTo === `/recipes`) {
            redirected()
        }
    },[redirectTo, redirected])


    const cssLoader = loader ? 'loaderParent' : ''

    return (
        <div className={`RecipesList ${cssLoader}`}>
            {
                redirectTo && <Redirect to={ redirectTo }/>
            }
            {
                loader ?
                    <Spinner
                        animation="border"
                        role="status"
                    >
                        <span className="sr-only">{ texts.spinner }</span>
                    </Spinner>
                :
                    recipes.map((recipe) => {
                        const isPublic = recipe.status_id === 1 ? '/public': ''
                        const url = recipe.author_id === userId ? `/recipe/${recipe.recipe_slug}` : `/recipe/${recipe.recipe_slug}${ isPublic }`
                        return (
                            <Link
                                to={ url }
                                key={`recipe-link_${recipe.recipe_slug}`}
                                className="recipe-link"
                            >

                                <Card
                                    className="recipe-card"

                                >
                                    <Card.Body>
                                        <img className={'img-thumbnail'} src={recipe.pictures[0]} alt="preview image of recipe"/>

                                        <Card.Header
                                            as={"h3"}
                                            className={"recipe-card-header"}
                                        >
                                            {recipe.recipe_name}
                                        </Card.Header>

                                        <div className={"card-info-global"} >

                                            <Card.Title as={"h4"}>
                                                {recipe.recipe_description}
                                            </Card.Title>

                                            <Card.Subtitle className="mb-2 text-muted">
                                                <Icon.VectorPen className={"author-icon"}/>
                                                <div>
                                                    {recipe.author_name}
                                                </div>
                                            </Card.Subtitle>

                                        </div>
                                        <div className="card-info-container">
                                            <Card.Text
                                                as="div"
                                                className={"card-recipe-info card-recipe-info-preparation-time"}
                                                data-tip={ texts.preparationTime }
                                            >
                                                <img
                                                    src={process.env.PUBLIC_URL + '/icons8-salt-bae-50.png'}
                                                    alt={"preparation time"}
                                                    className={"card-icons"}
                                                />
                                                <div className={"card-infos-value"}>
                                                    {recipe.recipe_preparation_time}
                                                </div>

                                            </Card.Text>
                                            <ReactTooltip/>

                                            <Card.Text
                                                as="div"
                                                className={"card-recipe-info card-recipe-info-cooking-time"}
                                                data-tip={ texts.cookingTime }
                                            >
                                                <img
                                                    src={process.env.PUBLIC_URL + '/icons8-frying-pan-50.png'}
                                                    alt={"cooking time"}
                                                    className={"card-icons"}
                                                />
                                                <div className={"card-infos-value"}>
                                                    {recipe.recipe_cooking_time}
                                                </div>
                                            </Card.Text>
                                            <ReactTooltip/>

                                            <Card.Text
                                                as="div"
                                                className={"card-recipe-info card-recipe-info-part-number"}
                                                data-tip={ texts.partNumber }
                                            >
                                                <Icon.PeopleFill/>
                                                <div className={"card-infos-value"}>
                                                    {recipe.part_number}
                                                </div>
                                            </Card.Text>
                                            <ReactTooltip/>

                                            <Card.Text
                                                as="div"
                                                className={"card-recipe-info card-recipe-info-tag-name"}
                                                data-tip={ texts.recipeTag }
                                            >
                                                <Icon.Book />
                                                <div className={"card-infos-value card-info-tag"}>
                                                    {recipe.tag_name}
                                                </div>
                                            </Card.Text>

                                        </div>

                                    </Card.Body>
                                </Card>
                            </Link>
                        )
                    })
            }
        </div>
    );
};

RecipesList.propTypes = {
    recipes: PropTypes.arrayOf(PropTypes.shape({
        recipe_name: PropTypes.string.isRequired,
        recipe_slug: PropTypes.string.isRequired,
        recipe_description: PropTypes.string.isRequired,
        author_name: PropTypes.string.isRequired,
        recipe_preparation_time: PropTypes.number.isRequired,
        recipe_cooking_time: PropTypes.number.isRequired,
        part_number: PropTypes.number.isRequired,
        tag_name: PropTypes.string.isRequired
    })),
    loader: PropTypes.bool.isRequired,
    isLogged: PropTypes.bool.isRequired,
    redirectTo: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool
    ]),
    userId: PropTypes.number,
    texts: PropTypes.shape({
        spinner: PropTypes.string.isRequired,
        preparationTime: PropTypes.string.isRequired,
        cookingTime: PropTypes.string.isRequired,
        partNumber: PropTypes.string.isRequired,
        recipeTag: PropTypes.string.isRequired
    }),

    getPublicRecipes: PropTypes.func.isRequired,
    getOwnRecipes: PropTypes.func.isRequired,
};

export default RecipesList;