/**
 * Import Modules
 */
import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types';
import {BiShow, BiHide, BiCheckDouble} from 'react-icons/bi';
import {Spinner} from "react-bootstrap";

/**
 * import components
 */


/**
 * Import Locals
 */
import './Register.scss'
import utility from "../../utility";

const allTrue = (obj) => {
    for(const o in obj)
        if(!obj[o]) return false;
    return true;
}


const Register =
    ({
         loader,
         texts,

        // func
         askRegisterSubmit,
         clearUserMessage,
     }) => {
        const [newUser, setNewUser] = useState({
            username: '',
            password: '',
            confirmePassword: '',
            first_name: '',
            last_name: '',
            email: ''
        })


        const [isValid, setIsValid] = useState({
            username: false,
            password: false,
            confirmePassword: false,
            first_name: false,
            last_name: false,
            email: false
        });

        const [showPassword, setShowPassword] = useState({
            password: false,
            confirmePassword: false,
        })

        const handleClickEyes = (evt) => {

            let dataset = evt.target.dataset.set

            if (evt.target.tagName === 'path') {
                dataset = evt.target.parentNode.dataset.set
            }
            setShowPassword({...showPassword, [dataset]: !showPassword[dataset]})

        }

        useEffect(() => {
            const editIsValid = utility.regexValidation( newUser, isValid )
                setIsValid(editIsValid)

        }, [newUser])


        const formReset = () => {
            setNewUser({
                username: '',
                password: '',
                confirmePassword: '',
                first_name: '',
                last_name: '',
                email: ''
            })
            setIsValid({
                username: false,
                password: false,
                confirmePassword: false,
                first_name: false,
                last_name: false,
                email: false
            })
        }


        const handleInputChange = (evt) => {
            setNewUser({...newUser, [evt.target.id]: evt.target.value})
        }

        const handleSubmit = (evt) => {
            evt.preventDefault()


            if (newUser.password === newUser.confirmePassword) {
                askRegisterSubmit( newUser )
            }
        }

        const handleClickReset = _ => {
            formReset()
        }

        useEffect(() => {
            clearUserMessage()
        },[])



        return (
            <div className={"Register"}>
                <h1 className="register-title">
                    { texts.title }
                </h1>
                {
                    loader ?
                        <Spinner animation="border" role="status">
                            <span className="sr-only">{ texts.spinner }</span>
                        </Spinner>
                    :
                        <form
                            className="register-form"
                            onSubmit={handleSubmit}
                        >
                            <div className="username-container formInput-container">
                                <label htmlFor="username">{ texts.username }</label>
                                {isValid.username && <BiCheckDouble className={"valid-input"}/>}

                                <input
                                    className={"username-input"}
                                    type="text"
                                    id="username"
                                    name="username"
                                    placeholder={ texts.usernamePlaceholder }
                                    value={newUser.username}
                                    onChange={handleInputChange}
                                />
                            </div>

                            <div className="password-container formInput-container">
                                <label htmlFor="password">{ texts.password }</label>
                                {isValid.password && <BiCheckDouble className={"valid-input"}/>}
                                <input
                                    className={"password-input"}
                                    type={showPassword.password ? 'text' : 'password'}
                                    id="password"
                                    name="password"
                                    placeholder={ texts.passwordPlaceholder }
                                    value={newUser.password}
                                    onChange={handleInputChange}
                                />
                                {
                                    showPassword ?
                                        <BiHide className={"input-eyes"} data-set={"password"} onClick={handleClickEyes}/> :
                                        <BiShow className={"input-eyes"} data-set={"password"} onClick={handleClickEyes}/>
                                }

                            </div>

                            <div className="confirmePassword-container formInput-container">
                                <label htmlFor="confirmePassword">{ texts.confirmePassword }</label>
                                {isValid.confirmePassword && <BiCheckDouble className={"valid-input"}/>}
                                <input
                                    className={"confirmePassword-input"}
                                    type={showPassword.confirmePassword ? 'text' : 'password'}
                                    id="confirmePassword"
                                    name="confirmePassword"
                                    placeholder={ texts.passwordPlaceholder }
                                    value={newUser.confirmePassword}
                                    onChange={handleInputChange}
                                />
                                {
                                    showPassword ? <BiHide className={"input-eyes"} data-set={"confirmePassword"}
                                                           onClick={handleClickEyes}/> :
                                        <BiShow className={"input-eyes"} data-set={"confirmePassword"}
                                                onClick={handleClickEyes}/>
                                }
                            </div>

                            <div className="firstName-container formInput-container">
                                <label htmlFor="firstName">{ texts.firstName }</label>
                                {isValid.first_name && <BiCheckDouble className={"valid-input"}/>}
                                <input
                                    className={"firstName-input"}
                                    type="text"
                                    id="first_name"
                                    name="first_name"
                                    placeholder={ texts.firstNamePlaceholder }
                                    value={newUser.first_name}
                                    onChange={handleInputChange}
                                />
                            </div>

                            <div className="lastName-container formInput-container">
                                <label htmlFor="lastName">{ texts.lastName}</label>
                                {isValid.last_name && <BiCheckDouble className={"valid-input"}/>}
                                <input
                                    className={"lastName-input"}
                                    type="text"
                                    id="last_name"
                                    name="last_name"
                                    placeholder={ texts.lastNamePlaceholder }
                                    value={newUser.last_name}
                                    onChange={handleInputChange}
                                />
                            </div>

                            <div className="email-container formInput-container">
                                <label htmlFor="email">{ texts.email }</label>
                                {isValid.email && <BiCheckDouble className={"valid-input"}/>}
                                <input
                                    className={"email-input"}
                                    type="text"
                                    id="email"
                                    name="email"
                                    placeholder={ texts.emailPlaceholder }
                                    value={newUser.email}
                                    onChange={handleInputChange}
                                />
                            </div>

                            <div className="buttons">
                                <input
                                    className={"submit-input"}
                                    type="submit"
                                    value={ texts.buttonValide }
                                    disabled={!allTrue(isValid)}
                                />

                                <input
                                    type="reset"
                                    value={ texts.buttonCancel }
                                    onClick={handleClickReset}
                                />
                            </div>

                        </form>
                }

            </div>
        );
    };

Register.propTypes = {
    loader: PropTypes.bool.isRequired,
    texts: PropTypes.shape({
        title: PropTypes.string.isRequired,
        spinner: PropTypes.string.isRequired,
        username: PropTypes.string.isRequired,
        usernamePlaceholder: PropTypes.string.isRequired,
        password: PropTypes.string.isRequired,
        passwordPlaceholder: PropTypes.string.isRequired,
        confirmePassword: PropTypes.string.isRequired,
        firstName: PropTypes.string.isRequired,
        firstNamePlaceholder: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        lastNamePlaceholder: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        emailPlaceholder: PropTypes.string.isRequired,
    }).isRequired,

    askRegisterSubmit: PropTypes.func.isRequired,
    clearUserMessage: PropTypes.func.isRequired,
};

export default Register;