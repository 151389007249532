import { combineReducers } from 'redux';
/* import your reducers here */
//import template from './reducer.template';
import user from './userReducer';
import recipes from './recipeReducer';
import navigation from './navigationReducer';
import files from './filesReducer';


const reducer = combineReducers({
  user,
  recipes,
  navigation,
  files
  /*template*/
});

export default reducer;