/**
 * Import Modules
 */
import React, {useState} from 'react'
import PropTypes from 'prop-types';
import {Link} from "react-router-dom";
import {Spinner} from "react-bootstrap";

/**
 * import components
 */


/**
 * Import Locals
 */
import './AskPasswordReset.scss'

const AskPasswordReset =
    ({
         loader,
         texts,

         //func
         askPasswordReset,
     }) => {
        const [email, setEmail] = useState('')

        const formReset = () => {
            setEmail('')
        }


        const handleInputChange = (evt) => {
            setEmail(evt.target.value)
        }

        const handleSubmit = (evt) => {
            evt.preventDefault()

            console.log('email', email)
            askPasswordReset({email})
            // to send on ajax request


        }

        const handleClickReset = _ => {
            formReset()
        }

        return (
            <div className={"AskPasswordReset"}>
                <h1 className="AskPasswordReset-title">
                    { texts.title }
                </h1>

                {
                    loader &&
                    <Spinner animation="border" role="status">
                        <span className="sr-only">{ texts.spinner }</span>
                    </Spinner>

                }

                <form
                    className="AskPasswordReset-form"
                    onSubmit={handleSubmit}
                >
                    <div className="email-container formInput-container">
                        <label htmlFor="email">{texts.inputLabel}</label>
                        <input
                            className={"email-input"}
                            type="text"
                            id="email"
                            name="email"
                            placeholder={ texts.inputPlaceholder }
                            value={email.email}
                            onChange={handleInputChange}
                        />
                    </div>


                    <div className="buttons">
                        <input
                            className={"submit-input"}
                            type="submit"
                            value={ texts.buttonValide }
                        />

                        <input
                            type="reset"
                            value={texts.buttonCancel}
                            onClick={handleClickReset}
                        />
                    </div>

                </form>
                <p className={"register-message"}>
                    { texts.message }
                    <Link
                        to={`/register`}
                        key={`register-link`}
                        className="register-link"
                    >
                        { texts.messageLinkValue }
                    </Link>
                </p>

            </div>
        );
    };

AskPasswordReset.propTypes = {
    loader: PropTypes.bool.isRequired,
    texts: PropTypes.shape({
        title: PropTypes.string.isRequired,
        spinner: PropTypes.string.isRequired,
        successMessage: PropTypes.string.isRequired,
        loginLinkValue: PropTypes.string.isRequired,
        inputLabel: PropTypes.string.isRequired,
        inputPlaceholder: PropTypes.string.isRequired,
        buttonValide: PropTypes.string.isRequired,
        buttonCancel: PropTypes.string.isRequired,
        message: PropTypes.string.isRequired,
        messageLinkValue: PropTypes.string.isRequired,
    }).isRequired,
    message: PropTypes.string.isRequired,
    status: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]).isRequired,

    askPasswordReset: PropTypes.func.isRequired,
};

export default AskPasswordReset;