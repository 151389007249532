/**
 * Import modules
 */
import {Link, Route, Switch, Redirect} from 'react-router-dom';
import {Divide as Hamburger} from 'hamburger-react'
import React, { useState, useEffect } from 'react'
import {Modal, Fade} from 'react-bootstrap';
import {BiPlus} from "react-icons/bi";
import ReactTooltip from "react-tooltip";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


/**
 * Import component
 */
import NotFound from './container/NotFound/NotFound';
import Footer from './container/Footer/Footer';
import SearchBar from './container/SearchBar/SearchBar';
import Welcome from './container/Welcome/Welcome';
import Register from './container/Register/Register';
import AppTitle from './components/AppTitle/AppTitle';
import UserInfos from './container/UserInfos/UserInfos';
import Login from './container/Login/Login';
import RecipesList from './container/RecipesList/RecipesList';
import Recipe from './container/Recipe/Recipe';
import AskPasswordReset from "./container/AskPasswordReset/AskPasswordReset";
import PasswordReset from "./container/PasswordReset/PasswordReset";
import ConfirmeEmail from "./container/ConfirmeEmail/ConfirmeEmail";
import UserAccount from "./container/UserAccount/UserAccount";
import AddNewRecipe from "./container/AddNewRecipe/AddNewRecipe";

/**
 * Import locals
 */
import './App.scss';
import './Header.scss'
import utility from "./utility";

const PrivateRoute = ({component: Component, authed, ...rest}) => {
    return (
        <Route
            {...rest}
            render={(props) => authed === true
                ? <Component {...props} />
                : <Redirect to={{pathname: '/login'}} />}
        />
    )
}


function App
({
     redirectTo,
     isLogged,
     texts,

     // func
     redirected,
     getTagsIngredientsUnitsStatus,
     loginSubmitSuccess,
     setLanguage,
     getPublicRecipes,
     getOwnRecipes,
 }) {

    /**
     * local state for handling modal openning
     */
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    /**
     * handle redirection
     */
    useEffect( () => {
        if (  redirectTo === false ) {
            return
        }
        if ( redirectTo === '/') {
            redirected()
        }
    },[redirected, redirectTo ])

    /**
     * get lists of tags, ingredients, units and status
     */
    useEffect(() => {
        getTagsIngredientsUnitsStatus()
    },[getTagsIngredientsUnitsStatus])

    /**
     * auto login from cookie
     */
    useEffect(  () => {
        const userToken = utility.getCookie( 'jwt' )
        utility.tokenDecrypt(userToken)
            .then( (res) => {
                if ( res.token ) {
                    const status = 200
                    loginSubmitSuccess({ status, user: { ...res.token }})
                    toast.success(`Bienvenue ${res.token.username}`);
                }
            })
    },[])

    /**
     * get recipes list
     */
    useEffect(  () => {
        const userToken = utility.getCookie( 'jwt' )
        utility.tokenDecrypt(userToken)
            .then( (res) => {
                if ( res.token ) {
                    getOwnRecipes( res.token.id )
                } else {
                    getPublicRecipes()
                }
            })
    },[])

    /**
     * translate from en to fr depending on browser settings
     */
    useEffect(() => {
        const userLang = navigator.language.split('-')[0]
        if ( userLang === 'fr' ) {
            setLanguage( userLang )
        }

    },[setLanguage])

    return (
        <div className="App text-center">
            <Fade in={show}>
                <Modal
                    show={show}
                    onHide={handleClose}
                    animation={true}
                    aria-labelledby="example-custom-modal-styling-title"
                >
                    <Modal.Header
                        closeButton
                        onClick={ handleClose }
                    >
                        <AppTitle />
                    </Modal.Header>
                    <Modal.Body
                        onClick={ handleClose }
                    >
                        <UserInfos/>
                    </Modal.Body>
                    <Modal.Footer>
                        <Link
                            to={`/add-new-recipe`}
                            key={`new-recipe-link`}
                            className="add-recipe"
                            onClick={ handleClose }
                        >
                            <BiPlus/>
                            { texts.addRecipeTooltip}
                        </Link>
                        <Route exact path="/recipes" component={SearchBar}/>
                        <Route exact path="/recipe/:slug" component={SearchBar}/>

                    </Modal.Footer>
                </Modal>
            </Fade>


            <div className="menuBar fixed-top">
                <div className={"d-none d-sm-none d-md-none d-lg-block d-xl-block"}>

                    <div
                        className={'Header'}
                    >
                        <AppTitle/>
                        <UserInfos/>
                    </div>

                    <Route
                        exact path="/recipes"
                        component={SearchBar}
                    />
                    <Route
                        exact path="/recipe/:slug"
                        component={SearchBar}
                    />
                    <Route
                        exact path="/user-account"
                        component={SearchBar}
                    />
                    <Route
                        exact path="/add-new-recipe"
                        component={SearchBar}
                    />

                    {
                        isLogged && <div
                        className="add-recipe"
                        data-tip={texts.addRecipeTooltip}
                        >
                            <Link
                                to={`/add-new-recipe`}
                                key={`new-recipe-link`}
                                className="add-recipe-img"
                            >
                                <BiPlus/>
                            </Link>
                        </div>
                    }

                    <ReactTooltip/>
                </div>
                <div className="d-block d-md-block d-lg-none d-xl-none">
                    <Hamburger
                        toggled={show}
                        toggle={handleShow}
                        rounded
                        className={'burger-menu'}
                    />
                </div>

            </div>
            <div className={"component-container d-flex align-items-start justify-content-center"}>
                <Switch>
                    <Route
                        exact path="/"
                        component={Welcome}
                    />
                    <Route
                        exact path="/register"
                        component={Register}
                    />
                    <Route
                        exact path="/login"
                        component={Login}
                    />
                    <Route
                        exact path="/recipes"
                        component={RecipesList}
                    />
                    <Route
                        exact path="/recipe/:slug"
                        component={Recipe}
                    />
                    <Route
                        exact path="/recipe/:slug/:isPublic"
                        component={Recipe}
                    />
                    <PrivateRoute
                        authed={isLogged}
                        exact path='/add-new-recipe'
                        component={AddNewRecipe}
                    />
                    <Route
                        exact path="/ask-password-reset"
                        component={AskPasswordReset}
                    />
                    <Route
                        exact path="/password-reset/:userId/:token"
                        component={PasswordReset}
                    />
                    <Route
                        exact path="/mailconfirme/:token"
                        component={ConfirmeEmail}
                    />
                    <PrivateRoute
                        authed={isLogged}
                        exact path='/user-account'
                        component={UserAccount}
                    />
                    <Route
                        exact path="/404"
                        component={NotFound}
                    />
                    <Route
                        component={NotFound}
                    />
                </Switch>
            </div>
            <div className="footer font-small fixed-bottom">
                <Footer/>

            </div>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    );
}

export default App;






