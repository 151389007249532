/**
 * Import locals
 */
import {
    LOGIN_SUBMIT,
    LOGIN_SUBMIT_FAIL,
    LOGIN_SUBMIT_SUCCESS,
    LOGOUT,
    LOGOUT_SUCCESS,
    LOGOUT_FAIL,
    EDIT_USER_SUBMIT,
    EDIT_USER_SUBMIT_FAIL,
    EDIT_USER_SUBMIT_SUCCESS,
    ASK_PASSWORD_RESET,
    ASK_PASSWORD_RESET_SUCCESS,
    ASK_PASSWORD_RESET_FAIL,
    PASSWORD_RESET_SUBMIT,
    PASSWORD_RESET_SUBMIT_SUCCESS,
    PASSWORD_RESET_SUBMIT_FAIL,
    ASK_REGISTER_SUBMIT,
    ASK_REGISTER_SUBMIT_SUCCESS,
    ASK_REGISTER_SUBMIT_FAIL,
    CONFIRME_MAIL_SUBMIT,
    CONFIRME_MAIL_SUBMIT_SUCCESS,
    CONFIRME_MAIL_SUBMIT_FAIL,
    DELETE_USER_SUBMIT,
    DELETE_USER_SUBMIT_SUCCESS,
    DELETE_USER_SUBMIT_FAIL,
} from "../actions/userActions";

import {
    CLEAR_USER_MESSAGE,
} from "../actions/navigationActions";

const INITIAL_STATE = {
    userToken: '',
    isLogged: false,
    loader: false,
    message: '',
    status: '',
    user: {
        id:''
    },
    showLogin: false,
};

const userReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case LOGIN_SUBMIT:
            return {
                ...state,
                loader: true

            };

        case LOGIN_SUBMIT_SUCCESS:
            return {
                ...state,
                user:action.payload.user,
                status:action.payload.status,
                loader: false,
                isLogged: true,
            };

        case LOGIN_SUBMIT_FAIL:
            return {
                ...state,
                ...action.payload,
                loader: false
            };

        case LOGOUT:
            return {
                ...state,
                loader: true

            };

        case LOGOUT_SUCCESS:
            return {
                ...state,
                userToken: '',
                user: {},
                ...action.payload,
                loader: false,
                isLogged: false,
            };

        case LOGOUT_FAIL:
            return {
                ...state,
                ...action.payload,
                loader: false
            };

        case EDIT_USER_SUBMIT:
            return {
                ...state,
                loader: true

            };

        case EDIT_USER_SUBMIT_SUCCESS:

            return {
                ...state,
                user: {...action.payload.data},
                message: action.payload.message,
                loader: false,
            };

        case EDIT_USER_SUBMIT_FAIL:
            return {
                ...state,
                ...action.payload,
                loader: false
            };

        case CLEAR_USER_MESSAGE:
            return {
                ...state,
                message: '',
                status: ''
            };

        case ASK_PASSWORD_RESET:
            return {
                ...state,
                loader: true

            };

        case ASK_PASSWORD_RESET_SUCCESS:
            return {
                ...state,
                loader: false,
                ...action.payload
            };

        case ASK_PASSWORD_RESET_FAIL:
            return {
                ...state,
                ...action.payload,
                loader: false
            };

        case PASSWORD_RESET_SUBMIT:
            return {
                ...state,
                loader: true

            };

        case PASSWORD_RESET_SUBMIT_SUCCESS:
            return {
                ...state,
                loader: false,
                ...action.payload
            };

        case PASSWORD_RESET_SUBMIT_FAIL:
            return {
                ...state,
                ...action.payload,
                loader: false
            };

        case ASK_REGISTER_SUBMIT:
            return {
                ...state,
                loader: true,
                showLogin: false,
            };

        case ASK_REGISTER_SUBMIT_SUCCESS:
            return {
                ...state,
                loader: false,
                ...action.payload,
                showLogin: true,
            };

        case ASK_REGISTER_SUBMIT_FAIL:
            return {
                ...state,
                ...action.payload,
                loader: false,
                showLogin: false,
            };

        case CONFIRME_MAIL_SUBMIT:
            return {
                ...state,
                loader: true,
                showLogin: false,
            };

        case CONFIRME_MAIL_SUBMIT_SUCCESS:
            return {
                ...state,
                loader: false,
                ...action.payload,
                showLogin: true,
            };

        case CONFIRME_MAIL_SUBMIT_FAIL:
            return {
                ...state,
                ...action.payload,
                loader: false,
                showLogin: false,
            };

        case DELETE_USER_SUBMIT:
            return {
                ...state,
                loader: true

            };

        case DELETE_USER_SUBMIT_SUCCESS:
            return {
                ...state,
                loader: false,
                ...action.payload
            };

        case DELETE_USER_SUBMIT_FAIL:
            return {
                ...state,
                ...action.payload,
                loader: false
            };

        default:
            return state;
    }
};

export default userReducer;