import { connect } from 'react-redux';
import { filesActions } from '../../store/actions';

import ViewPictures from '../../components/ViewPictures/ViewPictures';


const mapStateToProps = ( state ) => ({
    editMode: state.files.editMode,
});

const mapDispatchToProps = (dispatch) => ({
    set_edit_mode: () => {
        dispatch(filesActions.set_edit_mode());
    },

});



export default connect(mapStateToProps, mapDispatchToProps)(ViewPictures);

