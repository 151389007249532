/**
 * Import Modules
 */
import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types';
import {Card, Tabs, Tab, Form, Modal, Button} from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import {BiPencil, BiCheck, BiX, BiTrash, BiPlus, BiStop} from 'react-icons/bi';
import {MdPublic} from 'react-icons/md';
import {Redirect} from "react-router-dom";

import ReactTooltip from 'react-tooltip';

import {Typeahead} from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css';
import {Container, Draggable} from 'react-smooth-dnd';
import {Spinner} from "react-bootstrap";

/**
 * Import components
 */
import ViewPictures from "../../container/ViewPictures/ViewPictures";

/**
 * Import Locals
 */
import './Recipe.scss'
import utility from '../../utility'


/**
 * Function for sorting instruction list by order
 * @param a {Object}
 * @param b { Object}
 * @return {number}
 */
const compare = (a, b) => {
    if (a.instruction_order < b.instruction_order) {
        return -1;
    }
    if (a.instruction_order > b.instruction_order) {
        return 1;
    }
    return 0;
}


const utils = {
    isOwner: false
}


const Recipe =
    ({
         redirectTo,
         slug,
         isPublic,
         userId,
         recipe,
         loader,
         tags,
         units,
         status,
         ingredients,
         texts,

         //func
         getOneRecipesBySlug,
         editRecipeSubmit,
         clearUserMessage,
         deleteRecipeSubmit,
         redirected,
     }) => {
        useEffect( () => {
            if ( redirectTo === `/recipe/${slug}`) {
                redirected()
            }
        },[redirectTo, slug, redirected])

        useEffect(() => {
            (async() => {
                if ( userId === undefined ) {
                    return
                }
                await getOneRecipesBySlug({userId, slug, isPublic})
            })();

        }, [
            slug,
            userId
        ])


        /**
         * Initialisation of ingredients and instructions lists
         * @type {{}}
         */
        const initializeEditIngredient = {}
        const initializeEditInstructionArray = []

        /**
         * Set instruction list
         */
        useEffect(() => {

            if (recipe.hasOwnProperty('recipe_id')) {
                for (const ingredient of recipe.ingredients) {
                    ingredient.editMode = false
                    ingredient.toDelete = false
                    initializeEditIngredient[ingredient.ingredient_id] = ingredient
                }

                for (const [index, instruction] of recipe.instructions.sort(compare).entries()) {

                    instruction.editMode = false
                    instruction.toDelete = false
                    instruction.instruction_order = index
                    initializeEditInstructionArray.push(instruction)
                }

                setEditIngredient({...initializeEditIngredient})
                setInstructions([...initializeEditInstructionArray])

                utils.isOwner = recipe.author_id === userId
            }

        }, [
            recipe,
            userId
        ])

        /**
         * Local state for recipe must be moved to reducer
         */
        const [editRecipe, setEditRecipe] = useState({
            ...recipe,
            ingredientsToUnAssign: [],
            instructionsToDelete: [],
        })

        /**
         * Local state for recipe infos edition
         * set edit mode
         */
        const [editInfosMode, setEditInfosMode] = useState(false)

        /**
         * Local state for recipe infos edition
         * contains input values
         */
        const [editInfos, setEditInfos] = useState({
            recipe_id: recipe.recipe_id,
            recipe_name: recipe.recipe_name,
            recipe_description: recipe.recipe_description,
            author_name: recipe.author_name,
            recipe_preparation_time: recipe.recipe_preparation_time,
            recipe_cooking_time: recipe.recipe_cooking_time,
            part_number: recipe.part_number,
            tag_name: recipe.tag_name
        })

        /**
         * Local state for ingredients
         * set edit mode
         * @type {boolean}
         */
        const [globalEditIngredient, setGlobalEditIngredient] = useState(false)

        /**
         * Local state for ingredient list
         * contains ingredients list
         * @type {[{}]}
         */
        const [editIngredient, setEditIngredient] = useState({...initializeEditIngredient})

        /**
         * Local state for confirmation modal for ingredient deletion
         * set modal show
         * @type {boolean}
         */
        const [openIngredientWarningModal, setOpenIngredientWarningModal] = useState(false)

        /**
         * Local state for ingredient deletion
         * contains list of ingredients id
         * @type {[number]}
         */
        const [ingredientToDelete, setIngredientToDelete] = useState([])

        /**
         * Local state for ingredient ask deletion
         * @type {number}
         */
        const [ingredientAddedToDelete, setIngredientAddedToDelete] = useState('')

        /**
         * Local state for add new ingredient modal
         * set modal show
         * @type {boolean}
         */
        const [openAddNewIngredientModal, setOpenAddNewIngredientModal] = useState(false)

        /**
         * Local state for new ingredient creation
         * @type {Object}
         */
        const [newIngredient, setNewIngredient] = useState({
            id: '',
            ingredient: '',
            isNew: false,
            quantity: 0,
            unit_code: 'g',
            unit_id: 1,
            unit_name: 'Gramme',
        })


        /**
         * Local state for instructions
         * set edit mode
         * @type {boolean}
         */
        const [globalEditInstruction, setGlobalEditInstruction] = useState(false)

        /**
         * index of instruction to edit
         */
        const [instructionIndex, setInstructionIndex] = useState(undefined)

        /**
         * index of instruction to edit
         */
        const [editedInstruction, setEditedInstruction] = useState()

        /**
         * Local state for instruction list
         * contains instructions list
         * @type {[{}]}
         */
        const [instructions, setInstructions] = useState([...initializeEditInstructionArray])

        /**
         * Local state for confirmation modal for instruction deletion
         * set modal show
         * @type {boolean}
         */
        const [openInstructionWarningModal, setOpenInstructionWarningModal] = useState(false)

        /**
         * Local state for instruction deletion
         * contains list of instructions id
         * @type {[number]}
         */
        const [instructionsToDelete, setInstructionsToDelete] = useState([])

        /**
         * Local state for instruction ask deletion
         * @type {number}
         */
        const [instructionIndexAddedToDelete, setInstructionIndexAddedToDelete] = useState('')

        /**
         * Local state for instruction ask deletion
         * @type {number}
         */
        const [instructionAddedToDelete, setInstructionAddedToDelete] = useState('')

        /**
         * Local state for add new instruction modal
         * set modal show
         * @type {boolean}
         */
        const [openAddNewInstructionModal, setOpenAddNewInstructionModal] = useState(false)

        /**
         * Local state for new instruction creation
         * @type {Object}
         */
        const [newInstruction, setNewInstruction] = useState({})

        /**
         * Local state for confirmation modal for recipe deletion
         * set modal show
         * @type {boolean}
         */
        const [openRecipeWarningModal, setOpenRecipeWarningModal] = useState(false)

        /**
         * local state for add new ingredient submit activation
         * set modal show
         * @type { Object }
         */
        const [ checkValidSubmit, setCheckValidSubmit] = useState({
            ingredientSubmit: false,
            instructionSubmit: false,
        })

        /**
         * switch info edition mode
         */
        const handleClickEditInfos = () => {
            setEditInfosMode(!editInfosMode)
        }

        /**
         * handle recipe edition submit
         */
        const handleClickEditInfosSubmitChange = async () => {

            const data = {
                instructions,
                ingredients: Object.values(editIngredient),
            }

            setGlobalEditInstruction(false)
            setGlobalEditIngredient(false)

            await setEditRecipe({...editRecipe, ...editInfos, ...data})

            setEditInfosMode(!editInfosMode)
            editRecipeSubmit({...editInfos, instructions, ingredients: Object.values(editIngredient)}, userId, ingredientToDelete, instructionsToDelete )
            setInstructionAddedToDelete('')
            setInstructionsToDelete( [])
            setIngredientAddedToDelete('')
            setIngredientToDelete([])
        }

        /**
         * handle infos edition cancel changes
         */
        const handleClickEditInfosCancelChange = () => {
            setEditInfos({
                recipe_name: recipe.recipe_name,
                recipe_description: recipe.recipe_description,
                author_name: recipe.author_name,
                recipe_preparation_time: recipe.recipe_preparation_time,
                recipe_cooking_time: recipe.recipe_cooking_time,
                part_number: recipe.part_number,
                tag_name: recipe.tag_name
            })
            setEditInfosMode(!editInfosMode)
        }

        /**
         * handle input change on recipe info
         * @param evt event on change
         */
        const handleInputChange = (evt) => {
            const value = utility.capitalizeFirstLetter( evt.target.value )
            if ( evt.target.id === 'status_name' ) {
                const [ value1, value2] = value.split('-',2)
                setEditInfos({...editInfos, [evt.target.id]: value2, 'status_id': parseInt(value1) })
            } else {
                setEditInfos({...editInfos, [evt.target.id]: value})
            }
        }

        /**
         * switch ingredient edition mode
         */
        const handleClickEditIngredientMode = () => {
            setGlobalEditIngredient(true)
        }

        /**
         * switch edition one ingredient
         */
        const handleClickEditIngredient = (evt) => {
            const dataset = utility.getSVGDataset(evt)

            const editMode = editIngredient[parseInt(dataset.ingredientid)]

            editMode.editMode = !editMode.editMode

            setEditIngredient({
                ...editIngredient,
                [parseInt(dataset.ingredientid)]: {
                    ...editIngredient[parseInt(dataset.ingredientid)],
                    ...editMode
                }
            })
        }


        /**
         * handle ingredient's input change
         * @param evt
         */
        const handleIngredientInputChange = (evt) => {

            const {editedIngredient, ingredientId} = utility.handleIngredientInputChange(evt, editIngredient)

            setEditIngredient({...editIngredient, [ingredientId]: {...editedIngredient}})
        }

        /**
         * handle global cancel ingredient changes
         */
        const handleClickCancelEditIngredientMode = () => {
            for (const ingredient of recipe.ingredients) {
                ingredient.editMode = false
                ingredient.toDelete = false
                initializeEditIngredient[ingredient.ingredient_id] = ingredient
            }
            setEditIngredient({...initializeEditIngredient})
            setIngredientToDelete([])
            setGlobalEditIngredient(false)
        }

        /**
         * validate ingredient change
         * simply close de edit mode
         * @param evt
         */
        const handleSubmitIngredientChange = (evt) => {

            const dataset = utility.getSVGDataset(evt)

            const newIngredient = Object.values(editIngredient).filter((ingredient) => ingredient.ingredient_id === parseInt(dataset.ingredientid))[0]

            newIngredient.editMode = false
            setEditIngredient({...editIngredient, [parseInt(dataset.ingredientid)]: {...newIngredient}})
        }

        /**
         * handle cancel one ingredient change
         * @param evt
         */
        const handleClickEditIngredientCancelChange = (evt) => {

            const dataset = utility.getSVGDataset(evt)

            const originalIngredient = Object.values(recipe.ingredients).filter((ingredient) => ingredient.ingredient_id === parseInt(dataset.ingredientid))[0]

            originalIngredient.editMode = false
            setEditIngredient({...editIngredient, [parseInt(dataset.ingredientid)]: {...originalIngredient}})
        }

        /**
         * handle ask deletion of one ingredient
         * @param evt
         */
        const handleClickDeleteIngredient = (evt) => {

            const dataset = utility.getSVGDataset(evt)
            let deleteMode = editIngredient[parseInt(dataset.ingredientid)]
            if ( dataset.ingredientid.startsWith('new') ) {
             deleteMode =  editIngredient[dataset.ingredientid]
            }

            deleteMode.toDelete = true
            setIngredientAddedToDelete(deleteMode.ingredient_id)
            setEditIngredient({
                ...editIngredient,
                [deleteMode.ingredient_id]: {
                    ...editIngredient[deleteMode.ingredient_id],
                    ...deleteMode
                }
            })
            setOpenIngredientWarningModal(!openIngredientWarningModal)
        }

        /**
         * handle close modals
         */
        const handleCloseModal = () => {
            setOpenIngredientWarningModal(false)
            setOpenInstructionWarningModal(false)
            setOpenRecipeWarningModal(false)
        };

        /**
         * handle validate deletion
         */
        const handleValidDeleteIngredient = () => {

            const addToDelete = Object.values(editIngredient)
                .filter((ingredient) => ingredient.toDelete)
                .map((ingredient) => ingredient.ingredient_id)

            setIngredientToDelete(addToDelete)

            const editMode = editIngredient[ingredientAddedToDelete]
            editMode.editMode = false

            setEditIngredient({
                ...editIngredient,
                [ingredientAddedToDelete]: {
                    ...editIngredient[ingredientAddedToDelete],
                    ...editMode
                }
            })

            setIngredientAddedToDelete('')

            setOpenIngredientWarningModal(false)

        }

        /**
         * handle cancel delete
         */
        const handleCancelDeleteIngredient = () => {
            const cancelMode = editIngredient[ingredientAddedToDelete]
            cancelMode.toDelete = false
            setEditIngredient({
                ...editIngredient,
                [ingredientAddedToDelete]: {
                    ...editIngredient[ingredientAddedToDelete],
                    ...cancelMode
                }
            })
            setIngredientAddedToDelete('')

            setOpenIngredientWarningModal(!openIngredientWarningModal)
        }

        /**
         * open add new ingredient modal
         */
        const handleClickAddNewIngredientMode = () => {
            setOpenAddNewIngredientModal(!openAddNewIngredientModal)
        }

        /**
         * close add new ingredient modal
         */
        const handleCloseAddNewIngredientMode = () => {
            setOpenAddNewIngredientModal(false)
        }

        /**
         * cancel new ingredient creation
         * @constructor
         */
        const HandleAddNewIngredientCancel = (evt) => {
            evt.preventDefault()
            setNewIngredient( {
                id: '',
                ingredient: '',
                isNew: false,
                quantity: 0,
                unit_code: 'g',
                unit_id: 1,
                unit_name: 'Gramme',
            } )
            handleCloseAddNewIngredientMode()
        }

        /**
         * function to handle autocomplete selection
         * @param inputObject
         */
        const handleTypeAheadChange = ( inputObject ) => {
            if (!Array.isArray(inputObject) || inputObject.length === 0) {
                inputObject[0] = { name: '', id: '', isNew: false}
            }
            const buildNewIngredient= {}
            buildNewIngredient.ingredient = utility.capitalizeFirstLetter( inputObject[0].name )
            buildNewIngredient.id = inputObject[0].id
            buildNewIngredient.isNew = inputObject[0].customOption || false
            setNewIngredient({...newIngredient, ...buildNewIngredient})
        }

        /**
         * handle new ingredient inputs change
         * @param text
         * @param evt
         */
        const handleFormInputChange = ( evt, text ) => {

            let event= evt
            if ( typeof evt === 'string' ) {
                event= text
            }
            const buildNewIngredient = {}

            switch ( event.target.id) {
                case 'formQuantityinput':
                    buildNewIngredient.quantity = event.target.value
                    break

                case 'formSelect':
                    const unitId = event.target.selectedOptions[0].dataset.unitid
                    const unitName = event.target.selectedOptions[0].dataset.unitname

                    buildNewIngredient.unit_code = event.target.value
                    buildNewIngredient.unit_id = unitId
                    buildNewIngredient.unit_name = unitName
                    break

                default:
                    buildNewIngredient.ingredient = utility.capitalizeFirstLetter( event.target.value )

                    break
            }

            setNewIngredient({...newIngredient, ...buildNewIngredient})

        }

        /**
         * validate new ingredient creation
         * @param evt
         */
        const handleFormAddIngredientSubmit = (evt) => {
            evt.preventDefault()
            const value = utility.capitalizeFirstLetter(evt.target[0].value)

            const builtIngredient = {
                ingredient_id: `new-ingredient-${value}`,
                ingredient_name: value,
                ...newIngredient
            }

            setEditIngredient({...editIngredient, [builtIngredient.ingredient_id]: {...builtIngredient}})
            handleCloseAddNewIngredientMode()
        }

        /**
         * switch instruction edition mode
         */
        const handleClickEditInstructionMode = () => {
            setGlobalEditInstruction(true)
        }

        /**
         * handle new ingredient inputs change
         * @param evt
         */
        const handleFormInstructionInputChange = (evt) => {

            const temporaryId = instructions.length

            const buildNewInstruction = {
                instruction_id: `new-id-${temporaryId}`,
                instruction_description: utility.capitalizeFirstLetter( evt.target.value ),
                instruction_order: temporaryId,
                ref:null,
                editMode: false,
                toDelete: false
            }


            setNewInstruction({...buildNewInstruction})

        }

        /**
         * validate new instruction creation
         * @param evt
         */
        const handleFormAddInstructionSubmit = (evt) => {
            evt.preventDefault()

            setInstructions([...instructions, newInstruction])
        }

        /**
         * open new instruction creation modal
         */
        const handleClickAddNewInstructionMode = () => {
            setOpenAddNewInstructionModal(!openAddNewInstructionModal)

        }

        /**
         * global reset instruction change
         */
        const handleClickCancelEditInstructionMode = () => {

            setInstructions([...recipe.instructions])
            setInstructionsToDelete([])
            setGlobalEditInstruction(false)

        }

        /**
         * ask instruction deletion
         * @param evt
         */
        const handleClickDeleteInstruction = (evt) => {


            const dataset = utility.getSVGDataset(evt)


            setInstructionIndexAddedToDelete(parseInt(dataset.instruction_index))

            setInstructionAddedToDelete(instructions[parseInt(dataset.instruction_index)])


            setOpenInstructionWarningModal(!openInstructionWarningModal)
        }

        /**
         * cancel change for one instruction
         */
        const handleClickEditInstructionCancelChange = _ => {

            setInstructionIndex(undefined)
            setEditedInstruction({})
        }

        /**
         * switch edit mode for one instruction
         * @param evt
         */
        const handleClickEditInstruction = (evt) => {

            const dataset = utility.getSVGDataset(evt)
            const instructionId = parseInt(dataset.instruction_index)

            if (instructionIndex === instructionId) {
                setInstructionIndex(undefined)
                setEditedInstruction({})
            } else {
                setInstructionIndex(instructionId)

                setEditedInstruction(instructions[instructionId])

            }
        }

        /**
         * handle instruction input change
         * @param evt
         */
        const handleInstructionInputChange = evt => {

            const value = utility.capitalizeFirstLetter( evt.target.value )
            const instruction = {...editedInstruction}
            instruction[evt.target.id] = value

            setEditedInstruction(instruction)

        }

        /**
         * validate instruction change
         * @param _
         */
        const handleSubmitInstructionChange = _ => {

            const editedInstructions = [...instructions]

            editedInstructions[instructionIndex] = editedInstruction

            setInstructions(editedInstructions)
            setInstructionIndex(undefined)
            setEditedInstruction({})
        }

        /**
         * validate instruction delete
         */
        const handleValidDeleteInstruction = () => {

            const editInstructionsList = [...instructions]

            const editInstruction = {...instructionAddedToDelete}

            editInstruction.toDelete = true

            editInstructionsList[instructionIndexAddedToDelete] = editInstruction

            setInstructions([...editInstructionsList])

            const editInstructionsToDelete = [...instructionsToDelete]
            editInstructionsToDelete.push(editInstruction.instruction_id)

            setInstructionsToDelete([...editInstructionsToDelete])


            setInstructionIndexAddedToDelete('')
            setOpenInstructionWarningModal(false)

        }

        /**
         * cancel instruction delete
         */
        const handleCancelDeleteInstruction = () => {

            setInstructionAddedToDelete({})

            setInstructionIndexAddedToDelete('')

            setOpenInstructionWarningModal(false)

        }

        /**
         * handle modal close
         */
        const handleCloseAddNewInstructionMode = () => {

            setOpenAddNewInstructionModal(false)
        }

        const HandleAddNewInstructionSubmit = () => {

            handleCloseAddNewInstructionMode()
        }

        const HandleAddNewInstructionCancel = () => {

            setNewInstruction({})
            handleCloseAddNewInstructionMode()
        }

        /**
         * ask recipe delete
         */
        const handleClickDeleteRecipe = () => {

            setOpenRecipeWarningModal(!openRecipeWarningModal)
        }

        /**
         * validate recipe delete
         */
        const handleValidDeleteRecipe = () => {
            deleteRecipeSubmit({ userId, recipeId: recipe.recipe_id })
            setOpenRecipeWarningModal(!openRecipeWarningModal)
        }

        /**
         * cancel recipe delete
         */
        const handleCancelDeleteRecipe = () => {
            setOpenRecipeWarningModal(!openRecipeWarningModal)
        }

        /**
         * handle drop function
         * @param dropResult
         */
        const handleOnDrop = (dropResult) => {
            const result = utility.handleOnDrop(dropResult, instructions)

            setInstructions(result)
        }

        useEffect(() => {
            clearUserMessage()
        }, [clearUserMessage])

        useEffect(() => {
            setEditRecipe({...editRecipe, ...recipe})
            setEditInfos( recipe )
        },[
            recipe,
            setEditRecipe,
            setEditInfos
        ])

        useEffect(() => {
            setCheckValidSubmit({
                ingredientSubmit: utility.checkProperties(newIngredient),
                instructionSubmit: utility.checkProperties(newInstruction),
            })
        }, [newIngredient, newInstruction])


        return (
            <div className={"Recipe"}>
                {
                    redirectTo && <Redirect to={redirectTo}/>
                }
                <Modal
                    show={openIngredientWarningModal}
                    onHide={handleCloseModal}
                    animation={true}
                    aria-labelledby="confirm-modal"
                >
                    <Modal.Header closeButton>
                        <h1 className="confirm-modal-title h3">
                            { texts.confirmeDelete }
                        </h1>
                    </Modal.Header>

                    <Modal.Footer>
                        <div className="confirm-modal-body">
                            <Button
                                variant="outline-success"
                                onClick={handleValidDeleteIngredient}
                            >
                                { texts.buttonValide }
                            </Button>{' '}
                            <Button
                                variant="outline-danger"
                                onClick={handleCancelDeleteIngredient}
                            >
                                { texts.buttonCancel }
                            </Button>{' '}
                        </div>
                    </Modal.Footer>
                    <Modal.Body>
                    </Modal.Body>

                </Modal>


                <Modal
                    show={openAddNewIngredientModal}
                    onHide={handleCloseAddNewIngredientMode}
                    animation={true}
                    aria-labelledby="add-ingredient-modal"
                >
                    <Modal.Header closeButton>
                        <h1 className="add-ingredient-modal-title h3">
                            { texts.addIngredientModalTitle }
                        </h1>
                    </Modal.Header>
                    <Modal.Body>
                        <Form
                            onSubmit={handleFormAddIngredientSubmit}
                        >
                            <Form.Group controlId="formIngredientInput">
                                <Form.Label>{ texts.addIngredientLabelName }</Form.Label>
                                <Typeahead
                                    id="formIngredientInput"
                                    labelKey="name"
                                    options={ingredients}
                                    minLength={1}
                                    onInputChange={ handleFormInputChange }
                                    onChange={ handleTypeAheadChange }
                                    allowNew
                                    clearButton
                                    newSelectionPrefix={ texts.addIngredientCreateNewIngredient }
                                />
                            </Form.Group>

                            <Form.Group controlId="formQuantityinput">
                                <Form.Label>{ texts.addIngredientLabelQuantity }</Form.Label>
                                <Form.Control
                                    type="number"
                                    value={ newIngredient.quantity }
                                    onChange={handleFormInputChange}
                                />
                            </Form.Group>
                            <Form.Group controlId="formSelect">

                                <Form.Label>{ texts.addIngredientLabelUnit }</Form.Label>
                                <Form.Control
                                    as="select"
                                    onChange={handleFormInputChange}
                                >
                                    {
                                        units.map((unit) => <option key={`${unit.code}_${unit.id}`}
                                                                    value={unit.code} data-unitid={unit.id}
                                                                    data-unitname={unit.name}>{unit.name}</option>)
                                    }
                                </Form.Control>
                            </Form.Group>

                            <div className="add-ingredient-modal-body">
                                <Button
                                    variant="outline-success"
                                    type="submit"
                                    disabled={ !checkValidSubmit.ingredientSubmit }
                                >
                                    { texts.buttonValide }
                                </Button>
                                <Button
                                    type="cancel"
                                    variant="outline-danger"
                                    onClick={HandleAddNewIngredientCancel}
                                >
                                    { texts.buttonCancel }
                                </Button>
                            </div>
                        </Form>
                    </Modal.Body>

                </Modal>

                <Modal
                    show={openInstructionWarningModal}
                    onHide={handleCloseModal}
                    animation={true}
                    aria-labelledby="confirm-modal"
                >
                    <Modal.Header closeButton>
                        <h1 className="confirm-modal-title h3">
                            { texts.confirmeDelete }
                        </h1>
                    </Modal.Header>

                    <Modal.Footer>
                        <div className="confirm-modal-body">
                            <Button
                                variant="outline-success"
                                onClick={handleValidDeleteInstruction}
                            >
                                { texts.buttonValide }
                            </Button>{' '}
                            <Button
                                variant="outline-danger"
                                onClick={handleCancelDeleteInstruction}
                            >
                                { texts.buttonCancel }
                            </Button>{' '}
                        </div>
                    </Modal.Footer>
                    <Modal.Body>
                    </Modal.Body>

                </Modal>

                <Modal
                    show={openAddNewInstructionModal}
                    onHide={handleCloseAddNewInstructionMode}
                    animation={true}
                    aria-labelledby="add-instruction-modal"
                >
                    <Modal.Header closeButton>
                        <h1 className="add-instruction-modal-title h3">
                            { texts.addInstructionModalTitle }
                        </h1>
                    </Modal.Header>
                    <Modal.Body>
                        <Form
                            onSubmit={handleFormAddInstructionSubmit}
                        >
                            <Form.Group controlId="formInstructionInput">
                                <Form.Label>{ texts.addInstructionLabelDescription }</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="..."
                                    value={ newInstruction.instruction_description }
                                    onChange={handleFormInstructionInputChange}/>
                            </Form.Group>


                            <div className="add-instruction-modal-body">
                                <Button
                                    variant="outline-success"
                                    type="submit"
                                    onClick={HandleAddNewInstructionSubmit}
                                    disabled={ !checkValidSubmit.instructionSubmit }
                                >
                                    { texts.buttonValide }
                                </Button>
                                <div
                                    className="btn btn-outline-danger"
                                    onClick={HandleAddNewInstructionCancel}
                                >
                                    { texts.buttonCancel }
                                </div>
                            </div>
                        </Form>
                    </Modal.Body>

                </Modal>

                <Modal
                    show={openRecipeWarningModal}
                    onHide={handleCloseModal}
                    animation={true}
                    aria-labelledby="confirm-modal"
                >
                    <Modal.Header closeButton>
                        <h1 className="confirm-modal-title h3">
                            { texts.confirmeDelete }
                        </h1>
                    </Modal.Header>

                    <Modal.Footer>
                        <div className="confirm-modal-body">
                            <Button
                                variant="outline-success"
                                onClick={handleValidDeleteRecipe}
                            >
                                { texts.buttonValide }
                            </Button>{' '}
                            <Button
                                variant="outline-danger"
                                onClick={handleCancelDeleteRecipe}
                            >
                                { texts.buttonCancel }
                            </Button>{' '}
                        </div>
                    </Modal.Footer>
                    <Modal.Body>
                    </Modal.Body>

                </Modal>


                {
                    loader &&
                    <Spinner
                        animation="border"
                        role="status"
                    >
                        <span className="sr-only">{texts.spinner}</span>
                    </Spinner>
                }
                {
                    Object.values(recipe).length >0 &&
                            <Card
                                className="one-recipe-card"
                                style={{height: 'auto', width: '95%', borderRadius: '7px'}}
                            >
                                <Card.Body>

                                    <Card.Header
                                        as={"h3"}
                                        className={"recipe-card-header"}
                                    >
                                        {editInfosMode ?
                                            <>
                                                <BiTrash
                                                    className={"instruction-trash"}
                                                    data-recipeid={editInfos.recipe_id}
                                                    id={editInfos.recipe_id}
                                                    onClick={handleClickDeleteRecipe}
                                                />
                                                <div className="edit-recipe-name">
                                                    <input
                                                        key={`recipe_name`}
                                                        id={"recipe_name"}
                                                        type="text"
                                                        value={editInfos.recipe_name}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </>
                                            : <div className="recipe-card-name">
                                                {recipe.recipe_name}
                                            </div>
                                        }
                                        {editInfosMode ?
                                            <>
                                                <BiCheck
                                                    className={"submit-change"}
                                                    onClick={handleClickEditInfosSubmitChange}
                                                />
                                                <BiX
                                                    className={"cancel-change"}
                                                    onClick={handleClickEditInfosCancelChange}
                                                />
                                            </>
                                            :

                                            utils.isOwner &&
                                            <BiPencil
                                                className={"edit-pencil"}
                                                onClick={handleClickEditInfos}
                                                data-tip={ texts.globalPencil }
                                            />

                                        }

                                    </Card.Header>
                                    <div className={"spacer"}>
                                        <Card.Title as={"h4"}>
                                            {editInfosMode ?
                                                <div className="edit-recipe-description">
                                            <textarea
                                                id={"recipe_description"}
                                                value={editInfos.recipe_description}
                                                onChange={handleInputChange}
                                            />
                                                </div>
                                                : recipe.recipe_description}
                                        </Card.Title>
                                        <Card.Subtitle className="mb-2 text-muted">
                                            <Icon.VectorPen className={"author-icon"}/>
                                            <div>
                                                {recipe.author_name}
                                            </div>
                                        </Card.Subtitle>


                                    </div>
                                    <div className="card-info-container spacer">
                                        <Card.Text
                                            className={"card-recipe-info  card-recipe-info-preparation-time"}
                                            as={'div'}
                                            data-tip={ texts.preparationTime }
                                        >
                                            <img
                                                src={process.env.PUBLIC_URL + '/icons8-salt-bae-50.png'}
                                                alt={"preparation time"}
                                                className={"card-icons"}
                                            />
                                            <div className={"card-infos-value"}>
                                                {editInfosMode ?
                                                    <div className="edit-recipe-preparation-time">
                                                        <input
                                                            key={`recipe_preparation_time`}
                                                            id={"recipe_preparation_time"}
                                                            type="number"
                                                            value={editInfos.recipe_preparation_time}
                                                            onChange={handleInputChange}
                                                        />
                                                    </div>
                                                    : recipe.recipe_preparation_time}
                                            </div>

                                        </Card.Text>
                                        <ReactTooltip/>

                                        <Card.Text
                                            className={"card-recipe-info card-recipe-info-cooking-time"}
                                            as={'div'}
                                            data-tip={ texts.cookingTime }
                                        >
                                            <img
                                                src={process.env.PUBLIC_URL + '/icons8-frying-pan-50.png'}
                                                alt={"cooking time"}
                                                className={"card-icons"}
                                            />
                                            <div className={"card-infos-value"}>
                                                {editInfosMode ?
                                                    <div className="edit-recipe-cooking-time">
                                                        <input
                                                            key={`recipe_cooking_time`}
                                                            id={"recipe_cooking_time"}
                                                            type="number"
                                                            value={editInfos.recipe_cooking_time}
                                                            onChange={handleInputChange}
                                                        />
                                                    </div>
                                                    : recipe.recipe_cooking_time}
                                            </div>
                                        </Card.Text>
                                        <ReactTooltip/>

                                        <Card.Text
                                            className={"card-recipe-info card-recipe-info-part-number"}
                                            as={'div'}
                                            data-tip={ texts.partNumber }
                                        >
                                            <Icon.PeopleFill/>
                                            <div className={"card-infos-value"}>
                                                {editInfosMode ?
                                                    <div className="edit-recipe-part-number">
                                                        <input
                                                            key={`part_number`}
                                                            id={"part_number"}
                                                            type="number"
                                                            value={editInfos.part_number}
                                                            onChange={handleInputChange}
                                                        />
                                                    </div>
                                                    : recipe.part_number}
                                            </div>
                                        </Card.Text>
                                        <ReactTooltip/>

                                        <Card.Text
                                            className={"card-recipe-info card-recipe-info-tag-name"}
                                            as={'div'}
                                            data-tip={ texts.recipeTag }
                                        >
                                            <Icon.Book/>
                                            <div
                                                className={"card-infos-value card-info-tag"}
                                            >
                                                {editInfosMode ?
                                                    <div className="edit-recipe-tag">
                                                        <select
                                                            key={`tags`}
                                                            name="tags"
                                                            id="tag_name"
                                                            onChange={handleInputChange}
                                                        >
                                                            {
                                                                tags.map((tag) => {
                                                                    let tagHtml
                                                                    if (tag.tag_name === recipe.tag_name) {
                                                                        tagHtml =
                                                                            <option key={`tag_${tag.id}`}
                                                                                    value={tag.name}
                                                                                    selected={true}>{tag.name}</option>
                                                                    } else {
                                                                        tagHtml =
                                                                            <option key={`tag_${tag.id}`}
                                                                                    value={tag.name}>{tag.name}</option>
                                                                    }
                                                                    return tagHtml
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                    : recipe.tag_name}

                                            </div>
                                        </Card.Text>
                                        <ReactTooltip/>

                                        <Card.Text
                                            className={"card-recipe-info card-recipe-info-status"}
                                            as={'div'}
                                            data-tip={ texts.recipeStatus }
                                        >
                                            <MdPublic/>
                                            <div
                                                className={"card-infos-value card-info-status"}
                                            >
                                                {editInfosMode ?
                                                    <div className="edit-recipe-status">
                                                        <select
                                                            key={`status`}
                                                            name="status"
                                                            id="status_name"
                                                            onChange={handleInputChange}
                                                        >
                                                            {
                                                                status.map((status => {
                                                                    let statusHtml
                                                                    if (status.name === recipe.status_name) {
                                                                        statusHtml =
                                                                            <option key={`status_${status.id}`}
                                                                                    value={`${status.id}-${status.name}`}
                                                                                    selected={true}>{status.name}</option>
                                                                    } else {
                                                                        statusHtml =
                                                                            <option key={`status_${status.id}`}
                                                                                    value={`${status.id}-${status.name}`}>{status.name}</option>
                                                                    }
                                                                    return statusHtml
                                                                }))
                                                            }
                                                        </select>
                                                    </div>
                                                    : recipe.status_name}

                                            </div>
                                        </Card.Text>
                                        <ReactTooltip/>

                                    </div>


                                    <section className="main-content">
                                        <ViewPictures
                                            recipe={recipe}
                                            globalEditIngredient={globalEditIngredient}
                                            isOwner={utils.isOwner}
                                            texts
                                        />

                                        <Tabs
                                            defaultActiveKey="ingredients"
                                            id="Ingredients"
                                        >
                                            <Tab
                                                eventKey="ingredients"
                                                title="Ingredients"
                                            >
                                                <div
                                                    key={`ingredient_empty`}
                                                    className="ingredient ingredient-header"
                                                >
                                                    {globalEditIngredient ?
                                                        <>
                                                            <BiPlus
                                                                className={"add-ingredient"}
                                                                data-tip={ texts.addIngredientModalTitle }
                                                                onClick={handleClickAddNewIngredientMode}
                                                            />
                                                            <BiCheck
                                                                className={"submit-change"}
                                                                data-tip={ texts.globalCheck }
                                                                onClick={handleClickEditInfosSubmitChange}
                                                            />
                                                            <BiX
                                                                className={"cancel-change"}
                                                                data-tip={ texts.globalX }
                                                                onClick={handleClickCancelEditIngredientMode}
                                                            />
                                                            <ReactTooltip/>
                                                        </>
                                                        :
                                                        utils.isOwner && <>

                                                            <BiPencil
                                                                className={"edit-pencil"}
                                                                data-tip={ texts.globalPencil }
                                                                onClick={handleClickEditIngredientMode}
                                                            />
                                                            <ReactTooltip/>
                                                        </>
                                                    }
                                                </div>

                                                {

                                                    Object.values(editIngredient).map((ingredient) => {
                                                        const deleteCSS = ingredient.toDelete ? 'deleting' : ''
                                                        return (
                                                            <div
                                                                key={`ingredient_${ingredient.ingredient_id}_${ingredient.ingredient_name}`}
                                                                className={`ingredient ${deleteCSS}`}
                                                                data-ingredientid={ingredient.ingredient_id}
                                                                data-unitid={ingredient.unit_id}
                                                            >
                                                                {
                                                                    globalEditIngredient && !editIngredient[ingredient.ingredient_id].editMode &&
                                                                    <BiTrash
                                                                        className={"ingredient-trash"}
                                                                        onClick={handleClickDeleteIngredient}
                                                                        data-tip={ texts.individualTrash }

                                                                    />
                                                                }
                                                                    <ReactTooltip/>
                                                                <div className={"ingredient-name"}>
                                                                    {ingredient.ingredient_name}
                                                                </div>
                                                                <div className={"ingredient-quantity"}>


                                                                    {
                                                                        editIngredient[ingredient.ingredient_id].editMode ?
                                                                            <div className="edit-ingredient-quantity">
                                                                                <input
                                                                                    key={`quantity`}
                                                                                    id={"quantity"}
                                                                                    type="number"
                                                                                    value={editIngredient[ingredient.ingredient_id].quantity}
                                                                                    onChange={handleIngredientInputChange}
                                                                                />
                                                                            </div>
                                                                            : ingredient.quantity
                                                                    }

                                                                </div>
                                                                <div className={"ingredient-unit"}>
                                                                    {
                                                                        editIngredient[ingredient.ingredient_id].editMode ?
                                                                            <select
                                                                                key={`units`}
                                                                                name="units"
                                                                                id="unit_code"
                                                                                onChange={handleIngredientInputChange}
                                                                            >
                                                                                {
                                                                                    units.map((unit) => {
                                                                                        let tagHtml
                                                                                        if (unit.code === ingredient.unit_code) {
                                                                                            tagHtml =
                                                                                                <option
                                                                                                    key={`unit_${unit.code}`}
                                                                                                    value={unit.code}
                                                                                                    selected={true}>{unit.code}</option>
                                                                                        } else {
                                                                                            tagHtml =
                                                                                                <option
                                                                                                    key={`unit_${unit.code}`}
                                                                                                    value={unit.code}>{unit.code}</option>
                                                                                        }
                                                                                        return tagHtml
                                                                                    })
                                                                                }
                                                                            </select>
                                                                            : ingredient.unit_code
                                                                    }
                                                                </div>
                                                                {editIngredient[ingredient.ingredient_id].editMode ?
                                                                    <>
                                                                        <BiX
                                                                            className={"cancel-change"}
                                                                            onClick={handleClickEditIngredientCancelChange}
                                                                            data-tip={ texts.individualX }
                                                                        />
                                                                        <BiCheck
                                                                            className={"edit-pencil"}
                                                                            onClick={handleSubmitIngredientChange}
                                                                            data-tip={ texts.individualCheck }
                                                                        />
                                                                        <ReactTooltip/>
                                                                    </>
                                                                    :
                                                                    globalEditIngredient && <BiPencil
                                                                        className={"edit-pencil"}
                                                                        onClick={handleClickEditIngredient}
                                                                        data-tip={ texts.individualPencil }
                                                                    />
                                                                }
                                                                <ReactTooltip/>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </Tab>
                                            <Tab
                                                eventKey="instructions"
                                                title="Instructions"
                                            >
                                                <div
                                                    key={`ingredient_empty`}
                                                    className="instruction instruction-header"
                                                >
                                                    {globalEditInstruction ?
                                                        <>
                                                            <BiPlus
                                                                className={"add-instruction"}
                                                                data-tip={ texts.addInstructionModalTitle }
                                                                onClick={handleClickAddNewInstructionMode}
                                                            />
                                                            <BiCheck
                                                                className={"submit-change"}
                                                                data-tip={ texts.globalCheck }
                                                                onClick={handleClickEditInfosSubmitChange}
                                                            />
                                                            <BiX
                                                                className={"cancel-change"}
                                                                data-tip={ texts.globalX }
                                                                onClick={handleClickCancelEditInstructionMode}
                                                            />
                                                            <ReactTooltip/>
                                                        </>
                                                        :
                                                        utils.isOwner &&
                                                        <>
                                                            <BiPencil
                                                                className={"edit-pencil"}
                                                                data-tip={ texts.globalPencil }
                                                                onClick={handleClickEditInstructionMode}
                                                            />
                                                            <ReactTooltip/>
                                                        </>
                                                    }
                                                </div>

                                                <ol className={"instructions-list"}>
                                                    <Container
                                                        lockAxis="y"
                                                        dragHandleSelector=".dragndrop"
                                                        onDrop={handleOnDrop}

                                                    >
                                                        {
                                                            instructions.map((instruction, index) => {
                                                                const deleteCSS = instruction.toDelete ? 'deleting' : ''
                                                                return (
                                                                    <Draggable
                                                                        key={instruction.instruction_id}>
                                                                        <li
                                                                            key={`instructions_${instruction.instruction_id}`}
                                                                            className={`instruction ${deleteCSS}`}
                                                                            data-instructionid={instruction.instruction_id}
                                                                            data-instruction_index={instruction.instruction_order}
                                                                        >
                                                                            {
                                                                                globalEditInstruction ?
                                                                                    <>
                                                                                        <img
                                                                                            src={process.env.PUBLIC_URL + '/icons8-drag-reorder-50.png'}
                                                                                            alt={"cooking time"}
                                                                                            className={"dragndrop"}
                                                                                            data-tip={ texts.dragIcon }
                                                                                        />
                                                                                        {
                                                                                            instructionIndex === index ?
                                                                                                <BiX
                                                                                                    className={"cancel-change"}
                                                                                                    onClick={handleClickEditInstructionCancelChange}
                                                                                                    data-tip={ texts.individualX }
                                                                                                />
                                                                                                :
                                                                                                <BiTrash
                                                                                                    className={"instruction-trash"}
                                                                                                    onClick={handleClickDeleteInstruction}
                                                                                                    data-tip={ texts.individualTrash }
                                                                                                />
                                                                                        }
                                                                                        <ReactTooltip/>
                                                                                    </>
                                                                                    :
                                                                                    <BiStop/>
                                                                            }

                                                                            <div className="instruction-description">
                                                                                {instructionIndex === index ?
                                                                                    <>
                                                                            <textarea
                                                                                id={"instruction_description"}
                                                                                value={editedInstruction.instruction_description}
                                                                                onChange={handleInstructionInputChange}
                                                                            />
                                                                                    </>
                                                                                    :
                                                                                    <div
                                                                                        className="instruction-description">
                                                                                        {instruction.instruction_description}
                                                                                    </div>
                                                                                }

                                                                            </div>


                                                                            {instruction.editMode ?
                                                                                <>

                                                                                    <BiX
                                                                                        className={"cancel-change"}
                                                                                        onClick={handleClickEditInstructionCancelChange}
                                                                                    />
                                                                                    <ReactTooltip/>
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    {globalEditInstruction &&
                                                                                    <>
                                                                                        {instructionIndex === index ?
                                                                                            <BiCheck
                                                                                                className={"edit-pencil"}
                                                                                                onClick={handleSubmitInstructionChange}
                                                                                                data-tip={ texts.individualCheck}
                                                                                            />
                                                                                            :
                                                                                            <BiPencil
                                                                                                className={"edit-pencil"}
                                                                                                onClick={handleClickEditInstruction}
                                                                                                data-tip={ texts.individualPencil }
                                                                                            />
                                                                                        }
                                                                                    </>
                                                                                    }
                                                                                    <ReactTooltip/>
                                                                                </>
                                                                            }
                                                                        </li>
                                                                    </Draggable>
                                                                )
                                                            })
                                                        }
                                                    </Container>
                                                </ol>
                                            </Tab>

                                        </Tabs>
                                    </section>




                                </Card.Body>
                            </Card>
               }

            </div>
        );
    };

Recipe.propTypes = {
    redirectTo: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool
    ]),
    slug: PropTypes.string.isRequired,
    isPublic: PropTypes.string,
    userId: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]).isRequired,
    recipe: PropTypes.shape({}),
    loader: PropTypes.bool.isRequired,
    tags: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        color: PropTypes.string
    })),
    units: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        code: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired
    })),
    status: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired
})).isRequired,
    ingredients: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired
    })),
    texts: PropTypes.shape({
        spinner: PropTypes.string.isRequired,
        preparationTime: PropTypes.string.isRequired,
        cookingTime: PropTypes.string.isRequired,
        partNumber: PropTypes.string.isRequired,
        recipeTag: PropTypes.string.isRequired,
        confirmeDelete: PropTypes.string.isRequired,
        buttonValide: PropTypes.string.isRequired,
        buttonCancel: PropTypes.string.isRequired,
        addIngredientModalTitle: PropTypes.string.isRequired,
        addIngredientLabelName: PropTypes.string.isRequired,
        addIngredientLabelQuantity: PropTypes.string.isRequired,
        addIngredientLabelUnit: PropTypes.string.isRequired,
        addIngredientSelectUnit: PropTypes.string.isRequired,
        addInstructionModalTitle: PropTypes.string.isRequired,
        addInstructionLabelDescription: PropTypes.string.isRequired,
        errorRecipeNotExist: PropTypes.string.isRequired,
        globalCheck: PropTypes.string.isRequired,
        globalX: PropTypes.string.isRequired,
        globalPencil: PropTypes.string.isRequired,
        individualCheck: PropTypes.string.isRequired,
        individualX: PropTypes.string.isRequired,
        individualPencil: PropTypes.string.isRequired,
        individualTrash: PropTypes.string.isRequired,
        dragIcon: PropTypes.string.isRequired,
    }),

    getOneRecipesBySlug: PropTypes.func.isRequired,
    editRecipeSubmit: PropTypes.func.isRequired,
    clearUserMessage: PropTypes.func.isRequired,
    deleteRecipeSubmit: PropTypes.func.isRequired,
};

export default Recipe;