import { connect } from 'react-redux';
import { navigationActions, userActions } from '../../store/actions';
import UserInfos from '../../components/UserInfos/UserInfos';


const mapStateToProps = ( state ) => ({
    isLogged: state.user.isLogged,
    user: state.user.user,
    texts: state.navigation.texts.userInfos,
});

const mapDispatchToProps = (dispatch) => ({
    logout: () => {
        dispatch(userActions.logout());
    },
});



export default connect(mapStateToProps, mapDispatchToProps)(UserInfos);