/**
 * Import Modules
 */
import React, { useEffect, useState} from 'react'
import PropTypes from 'prop-types';
import {Tabs, Tab, Form, InputGroup, Modal, Button} from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import {BiX, BiTrash, BiPlus} from 'react-icons/bi';
import {Redirect} from "react-router-dom";

import ReactTooltip from 'react-tooltip';

import {Typeahead} from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css';
import {Container, Draggable} from 'react-smooth-dnd';


/**
 * import components
 */


/**
 * Import Locals
 */
import './AddNewRecipe.scss'
import utility from '../../utility'

const AddNewRecipe =
    (
        {
            userId,
            tags,
            units,
            userName,
            ingredientsList,
            texts,

            //func
            redirectTo,
            newRecipeSubmit,
        }
    ) => {

        /**
         * local state for recipe must be moved to reducer
         */
        const [newRecipe, setNewRecipe] = useState({})


        /**
         * local state for recipe infos edition
         * contains input values
         */
        const [newInfos, setNewInfos] = useState({
            name: '',
            description: '',
            author_name: '',
            recipe_preparation_time: '',
            recipe_cooking_time: '',
            part_number: '',
            tag_name: ''
        })

        /**
         * local state for ingredient list
         * contains ingredients list
         * @type {[{}]}
         */
        const [ingredients, setIngredients] = useState({})

        /**
         * local state for confirmation modal for ingredient deletion
         * set modal show
         * @type {boolean}
         */
        const [openIngredientWarningModal, setOpenIngredientWarningModal] = useState(false)


        /**
         * local state for ingredient ask deletion
         * @type {number}
         */
        const [ingredientAddedToDelete, setIngredientAddedToDelete] = useState('')

        /**
         * local state for add new ingredient modal
         * set modal show
         * @type {boolean}
         */
        const [openAddNewIngredientModal, setOpenAddNewIngredientModal] = useState(false)

        /**
         * local state for new ingredient creation
         * @type {Object}
         */
        const [newIngredient, setNewIngredient] = useState({
            id: '',
            ingredient: '',
            isNew: false,
            quantity: 0,
            unit_code: 'g',
            unit_id: 1,
            unit_name: 'Gramme',
        })

        /**
         * local state for instructions list
         */
        const [instructions, setInstructions] = useState([])

        /**
         * local state for instruction list
         * contains instructions list
         * @type {[{}]}
         */
        const [editInstruction, setEditInstruction] = useState({})

        /**
         * local state for confirmation modal for instruction deletion
         * set modal show
         * @type {boolean}
         */
        const [openInstructionWarningModal, setOpenInstructionWarningModal] = useState(false)

        /**
         * local state for instruction ask deletion
         * @type {number}
         */
        const [instructionAddedToDelete, setInstructionAddedToDelete] = useState('')

        /**
         * local state for add new instruction modal
         * set modal show
         * @type {boolean}
         */
        const [openAddNewInstructionModal, setOpenAddNewInstructionModal] = useState(false)

        /**
         * local state for new instruction creation
         * @type {Object}
         */
        const [newInstruction, setNewInstruction] = useState({
            instruction_description:'',
            instruction_order:'',
            ref:''
        })

        /**
         * local state for confirmation modal for recipe deletion
         * set modal show
         * @type {boolean}
         */
        const [openRecipeWarningModal, setOpenRecipeWarningModal] = useState(false)

        /**
         * local state for add new ingredient submit activation
         * set modal show
         * @type { Object }
         */
        const [ checkValidSubmit, setCheckValidSubmit] = useState({
            ingredientSubmit: false,
            instructionSubmit: false,
            recipeSubmit: false
        })

        /**
         * handle recipe edition submit
         */
        const handleNewRecipeSubmit = async (evt) => {
            evt.preventDefault()
            const ingredientsL = Object.values(ingredients)
            await setNewRecipe({...newRecipe, ...newInfos, 'ingredients': ingredientsL, instructions})

            newRecipe.author_name = userName
            newRecipe.author_id = userId
            newRecipe.tag_color = ''
            newRecipe.status_id = 2

            const recipe = {...newRecipe, ...newInfos, 'ingredients': ingredientsL, instructions}
            if ( utility.checkProperties( newRecipe ) ) {
                newRecipeSubmit({recipe}, userId)
            }

            /**
             * use useEffect on newRecipe change to dispatch to ajax middleware
             */
        }

        /**
         * handle input change on recipe info
         * @param evt event on change
         */
        const handleInputChange = (evt) => {
            setNewInfos({...newInfos, [evt.target.id]: evt.target.value})
        }

        /**
         * handle ingredient's input change
         * @param evt
         */
        const handleIngredientInputChange = (evt) => {
            const {editedIngredient, ingredientId} = utility.handleIngredientInputChange(evt, ingredients)

            setIngredients({...ingredients, [ingredientId]: {...editedIngredient}})
        }

        /**
         * handle global cancel ingredient changes
         */
        const handleClickCancelEditIngredientMode = () => {
            setIngredients({})
        }

        /**
         * handle ask deletion of one ingredient
         * @param evt
         */
        const handleClickDeleteIngredient = (evt) => {
            const dataset = utility.getSVGDataset(evt)

            let ingredientId = parseInt(dataset.ingredientid)
            if (dataset.ingredientid.startsWith('new-id')) {
                ingredientId = dataset.ingredientid
            }

            setIngredientAddedToDelete(ingredientId)
            setOpenIngredientWarningModal(!openIngredientWarningModal)
        }

        /**
         * handle close modals
         */
        const handleCloseModal = () => {
            setOpenIngredientWarningModal(false)
            setOpenInstructionWarningModal(false)
            setOpenRecipeWarningModal(false)
        };

        /**
         * handle validate deletion
         */
        const handleValidDeleteIngredient = () => {

            const deleteMode = {...ingredients}

            delete deleteMode[ingredientAddedToDelete]

            setIngredients({
                ...deleteMode
            })

            setIngredientAddedToDelete('')

            setOpenIngredientWarningModal(false)
        }


        /**
         * open add new ingredient modal
         */
        const handleClickAddNewIngredientMode = () => {
            setOpenAddNewIngredientModal(!openAddNewIngredientModal)
        }

        /**
         * close add new ingredient modal
         */
        const handleCloseAddNewIngredientMode = () => {
            setOpenAddNewIngredientModal(false)
            setNewIngredient({})
        }

        /**
         * cancel new ingredient creation
         * @constructor
         */
        const HandleAddNewIngredientCancel = () => {

            setNewIngredient({})
            setIngredients({...ingredients})
            setCheckValidSubmit({ ...checkValidSubmit, ingredientSubmit: false})

            handleCloseAddNewIngredientMode()
        }

        /**
         * function to handle autocomplete selection
         * @param inputObject
         */
        const handleTypeAheadChange = ( inputObject ) => {

            if (!Array.isArray(inputObject) || inputObject.length === 0) {
                inputObject[0] = { name: '', id: '', isNew: false}
            }

            const buildNewIngredient= {}
            buildNewIngredient.ingredient = utility.capitalizeFirstLetter( inputObject[0].name )
            buildNewIngredient.id = inputObject[0].id
            buildNewIngredient.isNew = inputObject[0].customOption || false
            setNewIngredient({...newIngredient, ...buildNewIngredient})
        }

        /**
         * handle new ingredient inputs change
         * @param evt
         * @param text
         */
        const handleFormIngredientInputChange = ( evt, text ) => {

            let event= evt
            if ( typeof evt === 'string' ) {
                event= text
            }
            const buildNewIngredient = {}

            switch ( event.target.id) {
                case 'formQuantityinput':
                    buildNewIngredient.quantity = event.target.value
                    break

                case 'formSelect':
                    const unitId = event.target.selectedOptions[0].dataset.unitid
                    const unitName = event.target.selectedOptions[0].dataset.unitname

                    buildNewIngredient.unit_code = event.target.value
                    buildNewIngredient.unit_id = unitId
                    buildNewIngredient.unit_name = unitName
                    break

                default:
                    buildNewIngredient.ingredient = utility.capitalizeFirstLetter( event.target.value )

                    break
            }

            setNewIngredient({...newIngredient, ...buildNewIngredient})

        }

        /**
         * validate new ingredient creation
         * @param evt
         */
        const handleFormAddIngredientSubmit = (evt) => {
            evt.preventDefault()

            const builtIngredient = {
                ingredient_id: newIngredient.id,
                ingredient_name: utility.capitalizeFirstLetter( evt.target[0].value ),
                isNew: newIngredient.isNew,
                quantity: newIngredient.quantity,
                unit_code: newIngredient.unit_code,
                unit_id: newIngredient.unit_id,
                unit_name: newIngredient.unit_name
            }

            if ( utility.checkProperties(builtIngredient) ) {
                setIngredients({...ingredients, [newIngredient.id]: {...builtIngredient}})
                handleCloseAddNewIngredientMode()
                setCheckValidSubmit({ ...checkValidSubmit, ingredientSubmit: false})
            }
        }


        /**
         * validate new instruction creation
         * @param evt
         */
        const handleFormAddInstructionSubmit = (evt) => {
            evt.preventDefault()

            const builtInstruction = {
                instruction_description: utility.capitalizeFirstLetter( evt.target[0].value ),
                instruction_order: evt.target[1].value,
            }

            setEditInstruction({...editInstruction, [newInstruction.id]: {...builtInstruction}})
        }

        /**
         * open new instruction creation modal
         */
        const handleClickAddNewInstructionMode = () => {
            setOpenAddNewInstructionModal(!openAddNewInstructionModal)

        }

        /**
         * handle input change for instruction creation
         * @param evt
         */
        const handleFormInstructionInputChange = (evt) => {

            const buildNewInstruction = {}

            buildNewInstruction[evt.target.id] = utility.capitalizeFirstLetter(evt.target.value)
            buildNewInstruction.instruction_order = instructions.length
            buildNewInstruction.ref = null

            setNewInstruction({...newInstruction, ...buildNewInstruction})
        }

        /**
         * global reset instruction change
         */
        const handleClickCancelEditInstructionMode = () => {

            setInstructions([])

        }

        /**
         * ask instruction deletion
         * @param evt
         */
        const handleClickDeleteInstruction = (evt) => {

            const dataset = utility.getSVGDataset(evt)

            const deleteMode = [...instructions]
            deleteMode.splice(dataset.instructionindex, 1);

            setInstructionAddedToDelete(dataset.instructionindex)

            setOpenInstructionWarningModal(!openInstructionWarningModal)


        }

        /**
         * handle instruction input change
         * @param evt
         */
        const handleInstructionInputChange = (evt) => {
            const dataset = evt.target.parentNode.parentNode.dataset

            const editedInstruction = editInstruction[parseInt(dataset.instructionid)]
            editedInstruction[evt.target.id] = evt.target.value

            setEditInstruction({...editInstruction, [parseInt(dataset.instructionid)]: {...editedInstruction}})

        }

        /**
         * validate instruction delete
         */
        const handleValidDeleteInstruction = () => {

            const deleteMode = [...instructions]

            deleteMode.splice(instructionAddedToDelete, 1)

            setInstructions([...deleteMode])

            setOpenInstructionWarningModal(false)
        }

        /**
         * handle modal close
         */
        const handleCloseAddNewInstructionMode = () => {
            setOpenAddNewInstructionModal(false)
        }

        const HandleAddNewInstructionSubmit = () => {

            if ( utility.checkProperties( newInstruction ) ) {
                setInstructions([...instructions, newInstruction])
                handleCloseAddNewInstructionMode()

                setNewInstruction({})
            }
        }

        const HandleAddNewInstructionCancel = () => {

            setNewInstruction({})

            handleCloseAddNewInstructionMode()
        }

        /**
         * validate recipe delete
         */
        const handleValidDeleteRecipe = () => {
            setOpenRecipeWarningModal(!openRecipeWarningModal)
        }

        /**
         * cancel recipe delete
         */
        const handleCancelDeleteRecipe = () => {
            setOpenRecipeWarningModal(!openRecipeWarningModal)
        }


        /**
         * handle drop function
         * @param dropResult
         */
        const handleOnDrop = (dropResult) => {

            const result = utility.handleOnDrop(dropResult, instructions)

            setInstructions(result)
        }

        const handleResetRecipeCreation = () => {
            setNewInfos({
                name: '',
                description: '',
                author_name: '',
                recipe_preparation_time: 0,
                recipe_cooking_time: 0,
                part_number: 0,
                tag_name: ''
            })
            setIngredients({})
            setNewIngredient({})
            setInstructions([])
            setNewInstruction({})
        }

        useEffect(() => {
            const newRecipe = { ...newInfos, 'ingredients':  Object.values(ingredients), instructions}
            newRecipe.author_name = userName
            newRecipe.author_id = userId
            newRecipe.tag_color = ''
            newRecipe.status_id = 2
            setCheckValidSubmit({
                ingredientSubmit: utility.checkProperties(newIngredient),
                instructionSubmit: utility.checkProperties(newInstruction),
                recipeSubmit: utility.checkProperties(newRecipe),
            })
        }, [
            ingredients,
            instructions,
            userId,
            userName,
            newIngredient,
            newInfos,
            newInstruction
        ])


        return (
            <div className={"AddNewRecipe"}>
                {
                    redirectTo && <Redirect to={redirectTo}/>
                }

                <Modal
                    show={openIngredientWarningModal}
                    onHide={handleCloseModal}
                    animation={true}
                    aria-labelledby="confirm-modal"
                >
                    <Modal.Header closeButton>
                        <h1 className="confirm-modal-title h3">
                            { texts.confirmeDelete }
                        </h1>
                    </Modal.Header>

                    <Modal.Footer>
                        <div className="confirm-modal-body">
                            <Button
                                variant="outline-success"
                                onClick={handleValidDeleteIngredient}
                            >
                                { texts.buttonValide}
                            </Button>{' '}
                            <Button
                                variant="outline-danger"
                                onClick={handleCloseModal}
                            >
                                { texts.buttonCancel }
                            </Button>{' '}
                        </div>
                    </Modal.Footer>
                    <Modal.Body>
                    </Modal.Body>

                </Modal>


                <Modal
                    show={openAddNewIngredientModal}
                    onHide={handleCloseAddNewIngredientMode}
                    animation={true}
                    aria-labelledby="add-ingredient-modal"
                >
                    <Modal.Header closeButton>
                        <h1 className="add-ingredient-modal-title h3">
                            { texts.addIngredientModalTitle }
                        </h1>
                    </Modal.Header>
                    <Modal.Body>
                        <Form
                            onSubmit={handleFormAddIngredientSubmit}
                        >
                            <Form.Group controlId="formIngredientInput">
                                <Form.Label>{ texts.addIngredientLabelName }</Form.Label>
                                <Typeahead
                                    id="formIngredientInput"
                                    labelKey="name"
                                    options={ingredientsList}
                                    minLength={1}
                                    onInputChange={ handleFormIngredientInputChange }
                                    onChange={ handleTypeAheadChange }
                                    allowNew
                                    clearButton
                                    newSelectionPrefix={ texts.addIngredientCreateNewIngredient }
                                />
                            </Form.Group>

                            <Form.Group controlId="formQuantityinput">
                                <Form.Label>{ texts.addIngredientLabelQuantity }</Form.Label>
                                <Form.Control
                                    type="number"
                                    value={ newIngredient.quantity }
                                    onChange={handleFormIngredientInputChange}
                                />
                            </Form.Group>
                            <Form.Group controlId="formSelect">

                                <Form.Label>{ texts.addIngredientLabelUnit }</Form.Label>
                                <Form.Control
                                    as="select"
                                    onChange={handleFormIngredientInputChange}
                                >
                                    {
                                        units.map((unit) => <option key={`${unit.code}_${unit.id}`}
                                                                    value={unit.code} data-unitid={unit.id}
                                                                    data-unitname={unit.name}>{unit.name}</option>)
                                    }
                                </Form.Control>
                            </Form.Group>

                            <div className="add-ingredient-modal-body">
                                <Button
                                    variant="outline-success"
                                    type="submit"
                                    disabled={ !checkValidSubmit.ingredientSubmit }
                                >
                                    { texts.buttonValide}
                                </Button>
                                <div
                                    className="btn btn-outline-danger"
                                    onClick={HandleAddNewIngredientCancel}
                                >
                                    { texts.buttonCancel }
                                </div>
                            </div>
                        </Form>
                    </Modal.Body>

                </Modal>

                <Modal
                    show={openInstructionWarningModal}
                    onHide={handleCloseModal}
                    animation={true}
                    aria-labelledby="confirm-modal"
                >
                    <Modal.Header closeButton>
                        <h1 className="confirm-modal-title h3">
                            { texts.confirmeDelete }
                        </h1>
                    </Modal.Header>

                    <Modal.Footer>
                        <div className="confirm-modal-body">
                            <Button
                                variant="outline-success"
                                onClick={handleValidDeleteInstruction}
                            >
                                { texts.buttonValide}
                            </Button>{' '}
                            <Button
                                variant="outline-danger"
                                onClick={handleCloseModal}
                            >
                                { texts.buttonCancel }
                            </Button>{' '}
                        </div>
                    </Modal.Footer>
                    <Modal.Body>
                    </Modal.Body>

                </Modal>

                <Modal
                    show={openAddNewInstructionModal}
                    onHide={handleCloseAddNewInstructionMode}
                    animation={true}
                    aria-labelledby="add-instruction-modal"
                >
                    <Modal.Header closeButton>
                        <h1 className="add-instruction-modal-title h3">
                            { texts.addInstructionModalTitle }
                        </h1>
                    </Modal.Header>
                    <Modal.Body>
                        <Form
                            onSubmit={handleFormAddInstructionSubmit}
                        >
                            <Form.Group controlId="instruction_description">
                                <Form.Label>{ texts.addInstructionLabelDescription }</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="..."
                                    onChange={handleFormInstructionInputChange}
                                />
                            </Form.Group>


                            <div className="add-instruction-modal-body">
                                <Button
                                    variant="outline-success"
                                    type="submit"
                                    onClick={HandleAddNewInstructionSubmit}
                                    disabled={ !checkValidSubmit.instructionSubmit }
                                >
                                    { texts.buttonValide}
                                </Button>
                                <Button
                                    type="cancel"
                                    variant="outline-danger"
                                    onClick={HandleAddNewInstructionCancel}
                                >
                                    { texts.buttonCancel }
                                </Button>
                            </div>
                        </Form>
                    </Modal.Body>

                </Modal>

                <Modal
                    show={openRecipeWarningModal}
                    onHide={handleCloseModal}
                    animation={true}
                    aria-labelledby="confirm-modal"
                >
                    <Modal.Header closeButton>
                        <h1 className="confirm-modal-title h3">
                            { texts.confirmeDelete }
                        </h1>
                    </Modal.Header>

                    <Modal.Footer>
                        <div className="confirm-modal-body">
                            <Button
                                variant="outline-success"
                                onClick={handleValidDeleteRecipe}
                            >
                                { texts.buttonValide}
                            </Button>{' '}
                            <Button
                                variant="outline-danger"
                                onClick={handleCancelDeleteRecipe}
                            >
                                { texts.buttonCancel }
                            </Button>{' '}
                        </div>
                    </Modal.Footer>
                    <Modal.Body>
                    </Modal.Body>

                </Modal>


                <Form
                    className={"card"}
                    onSubmit={handleNewRecipeSubmit}
                >
                    <Form.Group controlId="name" className={"recipe-title spacer"}>
                        <Form.Label srOnly>{ texts.addRecipeLabelName }</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={ texts.addRecipeNamePlaceholder }
                            value={newInfos.name}
                            onChange={handleInputChange}
                        />
                    </Form.Group>

                    <Form.Group controlId="description" className={"recipe-description spacer"}>
                        <Form.Label srOnly>{ texts.addInstructionLabelDescription }</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder= { texts.addRecipeDescriptionPlaceholder }
                            value={newInfos.description}
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                    <div className="recipe-infos spacer">
                        <Form.Group
                            controlId="recipe_preparation_time"
                            className={"recipe-preparation-time"}
                            data-tip={ texts.preparationTime }
                        >
                            <Form.Label srOnly>Recipe preparation time</Form.Label>
                            <InputGroup.Prepend>
                                <InputGroup.Text>
                                    <img
                                        src={process.env.PUBLIC_URL + '/icons8-salt-bae-50.png'}
                                        alt={ texts.preparationTime }
                                        className={"card-icons"}
                                    />
                                    <ReactTooltip/>
                                </InputGroup.Text>
                                <Form.Control
                                    type="number"
                                    min={'0'}
                                    placeholder={'0'}
                                    value={newInfos.recipe_preparation_time}
                                    onChange={handleInputChange}
                                />
                            </InputGroup.Prepend>
                        </Form.Group>
                        <ReactTooltip/>
                        <Form.Group
                            controlId="recipe_cooking_time"
                            className={"recipe-cooking-time"}
                            data-tip={ texts.cookingTime }
                        >
                            <Form.Label srOnly>Recipe cooking time</Form.Label>
                            <InputGroup.Prepend>
                                <InputGroup.Text
                                >
                                    <img
                                        src={process.env.PUBLIC_URL + '/icons8-frying-pan-50.png'}
                                        alt={ texts.cookingTime }
                                        className={"card-icons"}
                                    />
                                </InputGroup.Text>

                                <Form.Control
                                    type="number"
                                    min={'0'}
                                    placeholder={'0'}
                                    value={newInfos.recipe_cooking_time}
                                    onChange={handleInputChange}
                                />
                            </InputGroup.Prepend>
                        </Form.Group>
                        <ReactTooltip/>
                        <Form.Group
                            controlId="part_number"
                            className={"recipe-part-number"}
                            data-tip={ texts.partNumber }
                        >
                            <Form.Label srOnly>{ texts.partNumber }</Form.Label>
                            <InputGroup.Prepend>
                                <InputGroup.Text>
                                    <Icon.PeopleFill/>
                                </InputGroup.Text>
                                <Form.Control
                                    type="number"
                                    min={'0'}
                                    placeholder={'0'}
                                    value={newInfos.part_number}
                                    onChange={handleInputChange}
                                />
                            </InputGroup.Prepend>
                        </Form.Group>
                        <ReactTooltip/>
                        <Form.Group
                            controlId="tag_name"
                            className={"recipe-tag"}
                            data-tip={ texts.recipeTag }
                        >
                            <Form.Label srOnly>{ texts.recipeTag }</Form.Label>
                            <InputGroup.Prepend>
                                <InputGroup.Text>
                                    <Icon.Book/>
                                </InputGroup.Text>
                                <Form.Control
                                    as="select"
                                    value={newInfos.tag_name}
                                    onChange={handleInputChange}
                                >
                                    <option
                                        key={`tag_empty`}
                                        value={''}
                                    >
                                        { texts.addRecipeSelectTag }
                                    </option>
                                    {
                                        tags.map((tag) => <option key={`tag_${tag.name}`}
                                                                  value={tag.name}>{tag.name}</option>)
                                    }
                                </Form.Control>
                            </InputGroup.Prepend>
                        </Form.Group>
                        <ReactTooltip/>
                    </div>

                    <Tabs
                        defaultActiveKey="ingredients"
                        id="Ingredients"
                    >
                        <Tab
                            eventKey="ingredients"
                            title="Ingredients"
                        >
                            <div
                                key={`ingredient_empty`}
                                className="ingredient"
                            >
                                <>
                                    <BiPlus
                                        className={"add-ingredient"}
                                        data-tip={ texts.addIngredientModalTitle }
                                        onClick={handleClickAddNewIngredientMode}
                                    />

                                    <BiX
                                        className={"cancel-change"}
                                        data-tip={ texts.globalX }
                                        onClick={handleClickCancelEditIngredientMode}
                                    />
                                    <ReactTooltip/>
                                </>
                            </div>

                            {

                                Object.values(ingredients).map((ingredient) => {
                                    const deleteCSS = ingredient.toDelete ? 'deleting' : ''
                                    return (
                                        <div
                                            key={`ingredient_${ingredient.ingredient_id}_${ingredient.ingredient_name}`}
                                            className={`ingredient ${deleteCSS}`}
                                            data-ingredientid={ingredient.ingredient_id}
                                            data-unitid={ingredient.unit_id}
                                        >

                                            <div className={"ingredient-name"}>
                                                {ingredient.ingredient_name}
                                            </div>
                                            <div className={"ingredient-quantity"}>

                                                <div className="edit-ingredient-quantity">
                                                    <input
                                                        key={`quantity`}
                                                        id={"quantity"}
                                                        type="number"
                                                        value={ingredients[ingredient.ingredient_id].quantity}
                                                        onChange={handleIngredientInputChange}
                                                    />
                                                </div>

                                            </div>
                                            <div className={"ingredient-unit"}>
                                                <select
                                                    key={`units`}
                                                    name="units"
                                                    id="unit_code"
                                                    onChange={handleIngredientInputChange}
                                                >
                                                    {
                                                        units.map((unit) => {
                                                            let tagHtml
                                                            if (unit.code === ingredient.unit_code) {
                                                                tagHtml =
                                                                    <option
                                                                        key={`${unit.code}`}
                                                                        value={unit.code}
                                                                        selected={true}
                                                                    >
                                                                        {unit.code}
                                                                    </option>
                                                            } else {
                                                                tagHtml =
                                                                    <option
                                                                        key={`${unit.code}`}
                                                                        value={unit.code}
                                                                    >
                                                                        {unit.code}
                                                                    </option>
                                                            }
                                                            return tagHtml
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            <BiTrash
                                                className={ "ingredient-trash" }
                                                onClick={ handleClickDeleteIngredient }
                                                data-tip={ texts.individualTrash }
                                            />
                                            <ReactTooltip/>
                                        </div>
                                    )
                                })
                            }


                        </Tab>
                        <Tab
                            eventKey="instructions"
                            title="Instructions"
                        >
                            <div
                                key={`instruction_empty`}
                                className="instruction"
                            >
                                <BiPlus
                                    className={"add-instruction"}
                                    data-tip={ texts.addInstructionModalTitle }
                                    onClick={handleClickAddNewInstructionMode}
                                />

                                <BiX
                                    className={"cancel-change"}
                                    data-tip={ texts.globalX }
                                    onClick={handleClickCancelEditInstructionMode}
                                />
                                <ReactTooltip/>

                            </div>

                            <ol className={"instructions-list"}
                            >
                                <Container
                                    lockAxis="y"
                                    dragHandleSelector=".dragndrop"
                                    onDrop={handleOnDrop}
                                >
                                    {instructions.map((instruction, index) => {
                                        return (
                                            <Draggable key={instruction.instruction_description}>
                                                <li
                                                    key={`instructions_${instruction.instruction_description}`}
                                                    className={`instruction`}
                                                    data-instructionindex={index}
                                                >

                                                    <BiTrash
                                                        className={"instruction-trash"}
                                                        onClick={handleClickDeleteInstruction}
                                                        data-tip={ texts.individualTrash }
                                                    />


                                                    <div className="instruction-description">
                                                    <textarea
                                                        key={`instruction_description`}
                                                        id={"instruction_description"}
                                                        value={instruction.instruction_description}
                                                        onChange={handleInstructionInputChange}
                                                    />

                                                    </div>
                                                    <img
                                                        src={process.env.PUBLIC_URL + '/icons8-drag-reorder-50.png'}
                                                        alt={"cooking time"}
                                                        className={"dragndrop"}
                                                        data-tip={ texts.dragIcon }
                                                    />
                                                    <ReactTooltip/>

                                                </li>
                                            </Draggable>
                                        );
                                    })}
                                </Container>
                            </ol>


                        </Tab>

                    </Tabs>
                    <div className="buttons">
                        <button
                            type="submit"
                            disabled={ !checkValidSubmit.recipeSubmit }
                        >
                            { texts.buttonValide }
                        </button>
                        <button
                            type="cancel"
                            onClick={handleResetRecipeCreation}
                        >
                            { texts.buttonCancel }
                        </button>
                    </div>

                </Form>

            </div>
        );
    };

AddNewRecipe.propTypes = {
    userId: PropTypes.number.isRequired,
    userName: PropTypes.string.isRequired,
    tags: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        color: PropTypes.string
    })),
    units: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        code: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired
    })),
    ingredients: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired
    })),
    texts: PropTypes.shape({
        spinner: PropTypes.string.isRequired,
        preparationTime: PropTypes.string.isRequired,
        cookingTime: PropTypes.string.isRequired,
        partNumber: PropTypes.string.isRequired,
        recipeTag: PropTypes.string.isRequired,
        confirmeDelete: PropTypes.string.isRequired,
        buttonValide: PropTypes.string.isRequired,
        buttonCancel: PropTypes.string.isRequired,
        addIngredientModalTitle: PropTypes.string.isRequired,
        addIngredientLabelName: PropTypes.string.isRequired,
        addIngredientLabelQuantity: PropTypes.string.isRequired,
        addIngredientLabelUnit: PropTypes.string.isRequired,
        addIngredientSelectUnit: PropTypes.string.isRequired,
        addInstructionModalTitle: PropTypes.string.isRequired,
        addInstructionLabelDescription: PropTypes.string.isRequired,
        errorRecipeNotExist: PropTypes.string.isRequired,
        globalCheck: PropTypes.string.isRequired,
        globalX: PropTypes.string.isRequired,
        globalPencil: PropTypes.string.isRequired,
        individualCheck: PropTypes.string.isRequired,
        individualX: PropTypes.string.isRequired,
        individualPencil: PropTypes.string.isRequired,
        individualTrash: PropTypes.string.isRequired,
        dragIcon: PropTypes.string.isRequired,
        addRecipeLabelName: PropTypes.string.isRequired,
        addRecipeNamePlaceholder: PropTypes.string.isRequired,
        addRecipeDescriptionPlaceholder: PropTypes.string.isRequired,
        addRecipeSelectTag: PropTypes.string.isRequired,
    }),

    redirectTo: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool
    ]),
    newRecipeSubmit: PropTypes.func.isRequired,
};

export default AddNewRecipe;