import { connect } from 'react-redux';
import { filesActions } from '../../store/actions';

import UploadPictures from '../../components/UploadPictures/UploadPictures';


const mapStateToProps = ( state ) => ({
    texts: state.navigation.texts.UploadPictures,
});

const mapDispatchToProps = (dispatch) => ({
    uploadFile: ( payload, payload2 ) => {
        dispatch(filesActions.uploadFile( payload, payload2 ));
    },

});



export default connect(mapStateToProps, mapDispatchToProps)(UploadPictures);

