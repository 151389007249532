/**
 * import modules
 */
import React from "react";
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import './ViewPictures.scss';
import { BiPencil, BiX } from "react-icons/bi";
import ReactTooltip from "react-tooltip";

/**
 * import components
 */
import UploadPictures from "../../container/UploadPictures/UploadPictures";
import PicturesList from '../../container/PicturesList/PicturesList'


const ViewPictures = ({
    recipe,
    isOwner,
    texts,
    editMode,

    //func
    set_edit_mode
}) => {

    const handleClickEditPictures = () => {
        set_edit_mode()
    }

    return (
        <div className="ViewPictures spacer">
            {
                editMode ? <>
                        <div className={"edit-button"}>
                            <BiX
                                id={"edit-pictures"}
                                className={"cancel-change"}
                                onClick={handleClickEditPictures}
                                data-tip={ texts.individualX }
                            />
                        </div>
                        <UploadPictures recipeId={recipe.recipe_id} recipeSlug={ recipe.recipe_slug }/>
                        <PicturesList />
                </>
                    :
                <>
                    {
                        isOwner && <div className={"edit-button"}>

                            <BiPencil
                                id={"edit-pictures"}
                                className={"edit-pencil"}
                                data-tip={ texts.individualPencil }
                                onClick={handleClickEditPictures}
                            />
                            <ReactTooltip/>
                        </div>

                    }
                    <Carousel>
                        {
                            recipe.pictures.map((item , i) => (
                                <div key={item.size + i}>
                                    <img src={ window.location.origin+ '/' + item }  alt="img view"/>
                                    <p className="legend">{item.replace(`files/${recipe.author_id}/${recipe.recipe_id}/`,'').slice('.', -4)
                                    }</p>
                                </div>
                            ))
                        }
                    </Carousel>
                </>
            }
        </div>
    )
}

export default ViewPictures;