import { connect } from 'react-redux';
import { navigationActions } from '../../store/actions';

import Welcome from '../../components/Welcome/Welcome';


const mapStateToProps = ( state ) => ({
  text404: state.navigation.text404,
  redirectTo: state.navigation.redirectTo,
  isLogged: state.user.isLogged,
  texts: state.navigation.texts.welcomePage,
});

const mapDispatchToProps = (dispatch) => ({
  redirected: () => {
    dispatch(navigationActions.redirected());
  },
});



export default connect(mapStateToProps, mapDispatchToProps)(Welcome);

