/**
 * Import Modules
 */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";

/**
 * import components
 */

/**
 * Import Locals
 */
import './UserInfos.scss'


const UserInfos =
    ({
        isLogged,
        user,
        texts,

        //func
         logout,
     }) => {

        const handleClickLogout = (evt) => {
            console.log('handle logout', evt)
            logout()
        }


        useEffect(() => {

            //console.log('isLogged,user', isLogged, user)
        }, [ isLogged ])

        return (

            <div className="UserInfos">
                {
                    isLogged ?
                        <>
                            <Link
                                to={`/user-account`}
                                key={`user-account`}
                                className="user-account"
                            >
                                {`${user.username}${texts.accountAccess}`}
                            </Link>

                            <button
                                key={`user-logout`}
                                className="user-logout"
                                onClick={handleClickLogout}
                            >
                                { texts.logout }
                            </button>
                        </>
                        :
                        <>
                            <Link
                                to={`/login`}
                                key={`user-login`}
                                className="user-login"
                            >
                                { texts.login }
                            </Link>
                        </>
                }
            </div>
        );
    }
;

UserInfos.propTypes =
    {
        isLogged: PropTypes.bool.isRequired,
        user: PropTypes.shape({
            username: PropTypes.string
        }),
        texts: PropTypes.shape({
            accountAccess: PropTypes.string.isRequired,
            logout: PropTypes.string.isRequired,
            login: PropTypes.string.isRequired,
        }),

        logout: PropTypes.func.isRequired,
    }
;

export default UserInfos;