/**
 * Import modules
 */
import axios from 'axios';
import {toast} from "react-toastify";

/**
 * Import locals
 */
import {languages} from '../../text.json'
import utility from '../../utility'

/**
 * Import Actions
 */
import {
    GET_TAGS_INGREDIENTS_UNITS_STATUS,
    GET_PUBLIC_RECIPES,
    GET_OWN_RECIPES,
    GET_ONE_RECIPES_BY_SLUG,
    EDIT_RECIPE_SUBMIT,
    DELETE_RECIPE_SUBMIT,
    NEW_RECIPE_SUBMIT,
    getTagsIngredientsUnitsStatusSuccess,
    getTagsIngredientsUnitsStatusFail,
    getPublicRecipesSuccess,
    getPublicRecipesFail,
    getOwnRecipesSuccess,
    getOwnRecipesFail,
    getOneRecipesBySlug,
    getOneRecipesBySlugSuccess,
    getOneRecipesBySlugFail,
    editRecipeSubmitSuccess,
    editRecipeSubmitFail,
    deleteRecipeSubmitSuccess,
    deleteRecipeSubmitFail,
    getOwnRecipes,
    newRecipeSubmitFail,

} from "../actions/recipeActions";
import {redirect} from "../actions/navigationActions";
import {loginSubmitSuccess} from "../actions/userActions";

const utils = {
    url: utility.apiUrl,
    requestUrl: "",
    language: 'en',
};

export const recipeMiddleware = (store) => (next) => async (action) => {
    next(action);
    switch (action.type) {
        case GET_TAGS_INGREDIENTS_UNITS_STATUS:
            utils.requestUrl = `${utils.url}recipe/init`
            axios({
                method: "get",
                url: utils.requestUrl,
                data: {...action.payload},
                withCredentials: true,
            })
                .then(async (res) => {
                    store.dispatch(getTagsIngredientsUnitsStatusSuccess({...res.data, status: res.status}));
                    if ( res.data.user!== null ) {
                        store.dispatch(loginSubmitSuccess({ user:res.data.user, status: res.status}));
                        store.dispatch(getOwnRecipes(res.data.user.id))
                    }
                })
                .catch((err) => {
                    console.error(err.response);
                    store.dispatch(getTagsIngredientsUnitsStatusFail({
                        ...err.response.data,
                        status: err.response.status
                    }));
                });
            break

        case GET_PUBLIC_RECIPES:
            utils.requestUrl = `${utils.url}recipe/public`
            axios({
                method: "get",
                url: utils.requestUrl,
                data: {...action.payload},
                withCredentials: true,
            })
                .then(async (res) => {

                    store.dispatch(getPublicRecipesSuccess({...res.data, status: res.status}));
                })
                .catch((err) => {
                    console.error(err.response);
                    store.dispatch(getPublicRecipesFail({...err.response.data, status: err.response.status}));
                });
            break

        case GET_OWN_RECIPES:
            utils.requestUrl = `${utils.url}recipe/${action.payload}/recipes`
            axios({
                method: "get",
                url: utils.requestUrl,
                withCredentials: true,
            })
                .then(async (res) => {
                    store.dispatch(getOwnRecipesSuccess({...res.data, status: res.status}));
                })
                .catch((err) => {
                    console.error(err.response);
                    store.dispatch(getOwnRecipesFail({...err.response.data, status: err.response.status}));
                });
            break

        case GET_ONE_RECIPES_BY_SLUG:
            let userId = store.getState().user.user.id
            utils.language = store.getState().navigation.language

            const {slug, isPublic} = action.payload

            utils.requestUrl = isPublic !== undefined ? `${utils.url}recipe/${isPublic}/${slug}` : `${utils.url}recipe/${userId}/recipe/slug/${slug}`

            if ( isPublic === undefined && !userId ) {
                setTimeout(() => {
                    store.dispatch(getOneRecipesBySlug({ slug, isPublic}))
                }, 5)
                return
            }

            axios({
                method: "get",
                url: utils.requestUrl,
                withCredentials: true,
            })
                .then(async (res) => {
                    store.dispatch(getOneRecipesBySlugSuccess({...res.data, status: res.status}));
                })
                .catch((err) => {
                    console.error('error here',err.response);
                    store.dispatch(getOneRecipesBySlugFail({...err.response.data, status: err.response.status}));
                    store.dispatch(redirect('/404'))
                    toast.error(languages[utils.language].messages.getRecipeBySlugError);
                });
            break

        case EDIT_RECIPE_SUBMIT:
            utils.language = store.getState().navigation.language

            if (!Object.keys(action.payload).length) {
                store.dispatch(editRecipeSubmitFail({...action.payload}));
                break
            }
            utils.requestUrl = `${utils.url}recipe/${action.payload2}/recette/${action.payload.recipe_id}/editrecipe`

            axios({
                method: "put",
                url: utils.requestUrl,
                data: {
                    recipe: {...action.payload},
                    ingredientsToUnAssign: action.payload3,
                    instructionsToDelete: [...action.payload4],
                },
                withCredentials: true,
            })
                .then(async (res) => {

                    store.dispatch(editRecipeSubmitSuccess({...res.data, status: res.status}));
                    store.dispatch(getOwnRecipes(store.getState().user.user.id))
                    toast.success( languages[utils.language].messages.editRecipeSuccess);
                })
                .catch((err) => {
                    console.error(err.response);
                    store.dispatch(editRecipeSubmitFail({...err.response.data, status: err.response.status}));
                });
            break

        case DELETE_RECIPE_SUBMIT:
            utils.requestUrl = `${utils.url}recipe/${action.payload.userId}/recette/${action.payload.recipeId}/delete`
            axios({
                method: "delete",
                url: utils.requestUrl,
                withCredentials: true,
            })
                .then(async (res) => {

                    store.dispatch(redirect('/recipes'))
                    store.dispatch(deleteRecipeSubmitSuccess({...res.data, status: res.status}));
                    store.dispatch(getOwnRecipes(store.getState().user.user.id))
                    toast.success( languages[utils.language].messages.deleteRecipeSuccess);
                })
                .catch((err) => {
                    console.error(err.response);
                    store.dispatch(deleteRecipeSubmitFail({...err.response.data, status: err.response.status}));
                });
            break

        case NEW_RECIPE_SUBMIT:
            utils.requestUrl = `${utils.url}recipe/recipe/add`
            axios({
                method: "post",
                url: utils.requestUrl,
                data: {...action.payload},
                withCredentials: true,
            })
                .then(async (res) => {
                    store.dispatch(getOwnRecipes(action.payload2));
                    store.dispatch(redirect(`/recipe/${res.data[0].recipe_slug}`))
                })
                .catch((err) => {
                    console.error(err.response);
                    store.dispatch(newRecipeSubmitFail({...err.response.data, status: err.response.status}));
                });
            break


        default:
            return
    }
};