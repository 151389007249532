import { connect } from 'react-redux';
import { navigationActions, userActions } from '../../store/actions';

import Register from '../../components/Register/Register';


const mapStateToProps = ( state ) => ({
  redirectTo: state.navigation.redirectTo,
  message: state.user.message,
  loader: state.user.loader,
  status: state.user.status,
  texts: state.navigation.texts.user,
});

const mapDispatchToProps = (dispatch) => ({
  redirected: () => {
    dispatch(navigationActions.redirected());
  },

  askRegisterSubmit: ( payload ) => {
    dispatch(userActions.askRegisterSubmit( payload ));
  },

  clearUserMessage: () => {
    dispatch(navigationActions.clearUserMessage());
  },
});



export default connect(mapStateToProps, mapDispatchToProps)(Register);

