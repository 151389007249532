import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './NotFound.scss'


const NotFound = ({
  text404,
  redirectTo,
//FUNCTION
  redirected
}) => {
  useEffect( () => {
    if ( redirectTo === '/404') {
      redirected()
    }
  },[redirectTo, redirected])

  function getRandomIntInclusive(min, max) {
    min = Math.ceil( min );
    max = Math.floor( max );
    return Math.floor( Math.random() * ( max - min + 1 ) ) + min;
  }

  let randomNumber = getRandomIntInclusive(0, text404.length-1)

  return (
    <div className='NotFound'>
      <h1>404</h1>
      <p className="text404">
        {
          text404[randomNumber]
        }
      </p>
      <Link
        to={ `/` }
        key={ `return-lobby` }
        className="return-lobby"
      >
        Come back to lobby
      </Link>
    </div>
  );
};

NotFound.propTypes = {
  text404: PropTypes.arrayOf(PropTypes.string),
  redirectTo: PropTypes.string,
  redirected: PropTypes.func
};

export default NotFound;