import { connect } from 'react-redux';
import { navigationActions, recipeActions } from '../../store/actions';

import RecipesList from '../../components/RecipesList/RecipesList';


const mapStateToProps = ( state ) => ({
    redirectTo: state.navigation.redirectTo,
    message: state.recipes.message,
    recipes: state.recipes.filteredRecipes,
    loader: state.recipes.loader,
    isLogged: state.user.isLogged,
    userId: state.user.user.id || state.user.user.oauth_id,
    texts: state.navigation.texts.recipe,
});

const mapDispatchToProps = (dispatch) => ({
    redirected: () => {
        dispatch(navigationActions.redirected());
    },
    getPublicRecipes: () => {
        dispatch(recipeActions.getPublicRecipes());
    },
    getOwnRecipes: ( payload ) => {
        dispatch(recipeActions.getOwnRecipes( payload ));
    },
    clearUserMessage: () => {
        dispatch(navigationActions.clearUserMessage());
    },
    filterRecipes: ( payload ) => {
        dispatch(recipeActions.filterRecipes( payload ));
    },
});



export default connect(mapStateToProps, mapDispatchToProps)(RecipesList);

