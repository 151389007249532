
import { actionsTemplate } from '../actions';

import axios from 'axios';

export const navigationMiddleware = (store) => (next) => async (action) => {
  switch (action.type) {
    case actionsTemplate.RANDOM_EXAMPLE:
      // ...
      break
    default:
      next(action);
  }
};