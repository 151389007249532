
import { navigationActions } from '../actions';

import { languages, text404 } from '../../text.json'
const INITIAL_STATE = {
  redirectTo:false,
  creator: 'Belleval Olivier',
  language: 'en',
  texts: { },
  text404:[],

};
INITIAL_STATE.texts = { ...languages[INITIAL_STATE.language] }
INITIAL_STATE.text404 = [ ...text404[INITIAL_STATE.language] ]

const navigationReducer = (state = INITIAL_STATE, action)  => {
  switch (action.type) {
    case navigationActions.REDIRECT:
      return {
        ...state,
        redirectTo: action.payload
      };

    case navigationActions.REDIRECTED:
      return {
        ...state,
        redirectTo: false
      };

    case navigationActions.SET_LANGUAGE:
      return {
        ...state,
        language: action.payload,
        texts: { ...languages[ action.payload ] },
        text404: text404[ action.payload ]
      };

    default :
      return state;
  }
};

export default navigationReducer;