/**
 * Import setup
 */
import { REACT_APP_TOKEN_SECRET, DEV_ENV } from './config.json'
/**
 * Import modules
 */
import jwt from 'jsonwebtoken'

const utility = {

    apiUrl: DEV_ENV === 'local' ? "http://localhost:3000/" : "https://api.myfamilyrecipe.kilann.fr/",

    /**
     * function for drag n drop
     * @param arr
     * @param dragResult
     * @return {*[]|*}
     */
    applyDrag: (arr, dragResult) => {
        const { removedIndex, addedIndex, payload } = dragResult;
        if (removedIndex === null && addedIndex === null) return arr;

        const result = [...arr];
        let itemToAdd = payload;

        if (removedIndex !== null) {
            itemToAdd = result.splice(removedIndex, 1)[0];
        }

        if (addedIndex !== null) {
            result.splice(addedIndex, 0, itemToAdd);
        }

        return result;
    },

    /**
     * handle drop function
     * @param dropResult
     * @param instructions
     * @return {T[]}
     */
    handleOnDrop: (dropResult, instructions ) => {
        const newSortedList = utility.applyDrag(instructions, dropResult)

        return newSortedList.map((instruction, index) => {
            instruction.instruction_order = index
            return instruction
        })

    },

    /**
     * function to get dataset of the svg's parent
     * @param evt
     * @return {DOMStringMap}
     */
    getSVGDataset: ( evt ) => {
        let dataset = evt.target.parentNode.dataset
        if ( evt.target.nodeName === 'path' ) {
            dataset = evt.target.parentNode.parentNode.dataset
        }
        return dataset
    },

    /**
     * function to handle the ingredient input changes
     * @param evt
     * @param editIngredient
     * @return {{ingredientId: number, editedIngredient}}
     */
    handleIngredientInputChange: ( evt, editIngredient ) => {
        let dataset
        switch (evt.target.id) {
            case 'quantity':
                dataset = evt.target.parentNode.parentNode.parentNode.dataset
                break
            case 'unit_code':
                dataset = evt.target.parentNode.parentNode.dataset
                break
            default :
                return
        }

        const editedIngredient = editIngredient[parseInt(dataset.ingredientid)]
        editedIngredient[evt.target.id]= evt.target.value

        return {editedIngredient, ingredientId: parseInt(dataset.ingredientid) }

    },

    /**
     * function to get a cookie by is name
     * @param name
     * @return {string}
     */
    getCookie: ( name ) => {
        let cookieValue
        if (document.cookie && document.cookie !== '') {
            cookieValue = document.cookie
                .split('; ')
                .find(row => row.startsWith(`${name}=`))
                .split('=')[1]
        }
        return cookieValue;
    },

    /**
     * decode token tool
     * @param { string } token
     * @return {Promise<*>}
     */
    tokenDecrypt: async ( token ) => {

        return await jwt.verify(token, REACT_APP_TOKEN_SECRET,
            async (err, decodedToken) => {
                return {err, token: decodedToken}
            })
    },

    /**
     * function capitalise the first lettre
     * @param string
     * @return {string}
     */
    capitalizeFirstLetter: ( string ) => {
        return string.charAt(0).toUpperCase() + string.slice(1)
    },

    /**
     * check if all properties of object not empty
     * @param obj
     * @return {boolean}
     */
    checkProperties: (obj) => {
        for (const key in obj) {

            if (key !== 'isNew' && key !== 'tag_color' && key !== 'ref' && obj[key].length !== undefined ) {
                if ( obj[key].length === 0 ) {
                    return false;
                }
            }
        }
        return true;
    },

    /**
     * function to validate regex for user creation and editing
     * @param user
     * @param isValid
     * @return {*}
     */
    regexValidation: ( user, isValid) => {
        const editIsValid = {...isValid}
        const usernameRegex = new RegExp("^(?=.{5,})")
        const passwordRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{8,})");
        const confirmPasswordRegex = new RegExp("^(?=.{1,})")
        const namesRegex = new RegExp("^(?=.{3,})")
        const emailRegex = new RegExp("^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$")

        for (const [key, value] of Object.entries(user)) {
            isValid[key] = false
            switch (key) {
                case 'username':
                    usernameRegex.test(value) ? editIsValid[key] = true : editIsValid[key] = false
                    break;
                case 'password':
                    passwordRegex.test(value) ? editIsValid[key] = true : editIsValid[key] = false
                    break
                case 'confirmePassword':
                    if (confirmPasswordRegex.test(value)) {
                        value === user.password ? editIsValid[key] = true : editIsValid[key] = false
                    }
                    break
                case 'first_name':
                    namesRegex.test(value) ? editIsValid[key] = true : editIsValid[key] = false
                    break
                case 'last_name':
                    namesRegex.test(value) ? editIsValid[key] = true : editIsValid[key] = false
                    break
                case 'email':
                    emailRegex.test(value) ? editIsValid[key] = true : editIsValid[key] = false
                    break
            }
        }
        return editIsValid
    },

    /**
     * delay function
     * @param milliseconds
     */
    sleep: (milliseconds) => {
        const date = Date.now();
        let currentDate = null;
        do {
            currentDate = Date.now();
        } while (currentDate - date < milliseconds);
    }
}

export default utility;