/**
 * Import Modules
 */
import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types';
import {BiShow, BiHide, BiPencil} from 'react-icons/bi';
import {Button, Modal, Spinner} from "react-bootstrap";
import {Redirect} from "react-router-dom";
import {Trash} from "react-bootstrap-icons";

/**
 * import components
 */


/**
 * Import Locals
 */
import './UserAccount.scss'
import utility from "../../utility";


const UserAccount =
    ({
         user,
         loader,
         status,
         redirectTo,
         texts,

         //func
         editUserSubmit,
         clearUserMessage,
         deleteUserSubmit,
     }) => {

        const [editUSer, setEditUSer] = useState({
            id: user.id || user.oauth_id,
            username: user.username,
            password: '',
            confirmePassword: '',
            first_name: user.first_name,
            last_name: user.last_name,
            email: user.email,
            picture: null,
        })


        const [isValid, setIsValid] = useState({
            username: false,
            password: false,
            confirmePassword: false,
            first_name: false,
            last_name: false,
            email: false
        });

        const [showPassword, setShowPassword] = useState({
            password: false,
            confirmePassword: false,
        })

        const [editMode, setEditMode] = useState(false)

        const [show, setShow] = useState(false);

        const handleClickEyes = (evt) => {

            let dataset = evt.target.dataset.set

            if (evt.target.tagName === 'path') {
                dataset = evt.target.parentNode.dataset.set
            }
            setShowPassword({...showPassword, [dataset]: !showPassword[dataset]})

        }

        useEffect(() => {

            const editIsValid = utility.regexValidation(editUSer, isValid)
            setIsValid(editIsValid)

        }, [])

        const formReset = () => {
            setEditUSer({
                username: user.username,
                password: '',
                confirmePassword: '',
                first_name: user.first_name,
                last_name: user.last_name,
                email: user.email,
                picture: null,
            })
        }


        const handleInputChange = (evt) => {
            setEditUSer({...editUSer, [evt.target.id]: evt.target.value})
        }

        const handleSubmit = (evt) => {
            evt.preventDefault()

            if (editUSer.password === editUSer.confirmePassword || editUSer.password.length === '') {

                editUserSubmit(editUSer)
                // to send on ajax request
            } else {

            }

        }

        const handleClickReset = _ => {

            setEditMode( false )
            formReset()
        }

        const handleClickEdit = () => {
            setEditMode(!editMode)
        }

        const handleClickShowModal = () => {
            setShow(true)
        }

        const handleClose = () => {
            setShow(false)
        }

        const handleValidDeleteUser = () => {
            deleteUserSubmit(user.id)
            setShow(false)
        }

        useEffect(() => {
            clearUserMessage()
        }, [])

        return (
            <div className={"UserAccount"}>
                <Modal
                    show={show}
                    onHide={handleClose}
                    animation={true}
                    aria-labelledby="confirm-modal"
                >
                    <Modal.Header closeButton>
                        <h1 className="confirm-modal-title h3">
                            {texts.modalTitle}
                        </h1>
                    </Modal.Header>
                    <Modal.Body>
                        {texts.modalMessage}
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="confirm-modal-body">
                            <Button
                                variant="outline-success"
                                onClick={handleValidDeleteUser}
                            >
                                {texts.buttonValide}
                            </Button>{' '}
                            <Button
                                variant="outline-danger"
                                onClick={handleClose}
                            >
                                {texts.buttonCancel}
                            </Button>{' '}
                        </div>
                    </Modal.Footer>
                    <Modal.Body>
                    </Modal.Body>
                </Modal>
                {
                    redirectTo && <Redirect to={redirectTo}/>
                }
                <h1 className="account-title">
                    {`${user.first_name} ${user.last_name}`}
                    <BiPencil
                        id={"editpencil"}
                        className={"editpencil"}
                        onClick={handleClickEdit}
                    />
                    {
                        editMode && <Trash onClick={handleClickShowModal}/>
                    }
                </h1>
                {
                    loader &&
                    <Spinner animation="border" role="status">
                        <span className="sr-only">{texts.spinner}</span>
                    </Spinner>
                }
                <form
                    className="account-form"
                    onSubmit={handleSubmit}
                >
                    <div className="email-container formInput-container">
                        <label htmlFor="email">{texts.email}</label>
                        <input
                            className={"email-input"}
                            type="text"
                            id="email"
                            name="email"
                            placeholder={texts.emailPlaceholder}
                            value={editUSer.email}
                            onChange={handleInputChange}
                            disabled={true}
                        />
                    </div>

                    <div className="username-container formInput-container">
                        <label htmlFor="username">{texts.username}</label>
                        <input
                            className={"username-input"}
                            type="text"
                            id="username"
                            name="username"
                            placeholder={texts.usernamePlaceholder}
                            value={editUSer.username}
                            onChange={handleInputChange}
                            disabled={!editMode}
                        />
                    </div>

                    <div className="firstName-container formInput-container">
                        <label htmlFor="firstName">{texts.firstName}</label>
                        <input
                            className={"firstName-input"}
                            type="text"
                            id="first_name"
                            name="first_name"
                            placeholder={texts.firstNamePlaceholder}
                            value={editUSer.first_name}
                            onChange={handleInputChange}
                            disabled={!editMode}
                        />
                    </div>

                    <div className="lastName-container formInput-container">
                        <label htmlFor="lastName">{texts.lastName}</label>
                        <input
                            className={"lastName-input"}
                            type="text"
                            id="last_name"
                            name="last_name"
                            placeholder={texts.lastNamePlaceholder}
                            value={editUSer.last_name}
                            onChange={handleInputChange}
                            disabled={!editMode}
                        />
                    </div>
                    {
                        editMode &&
                        <>
                            <div className="password-container formInput-container">
                                <label htmlFor="password">{texts.password}</label>
                                <input
                                    className={"password-input"}
                                    type={showPassword.password ? 'text' : 'password'}
                                    id="password"
                                    name="password"
                                    placeholder={texts.passwordPlaceholder}
                                    value={editUSer.password}
                                    onChange={handleInputChange}
                                    disabled={!editMode}
                                />
                                {
                                    showPassword.password ?
                                        <BiShow className={"input-eyes"} data-set={"password"}
                                                onClick={handleClickEyes}/>
                                        :
                                        <BiHide className={"input-eyes"} data-set={"password"}
                                                onClick={handleClickEyes}/>
                                }

                            </div>

                            <div className="confirmePassword-container formInput-container">
                                <label htmlFor="confirmePassword">{texts.confirmePassword}</label>
                                <input
                                    className={"confirmePassword-input"}
                                    type={showPassword.confirmePassword ? 'text' : 'password'}
                                    id="confirmePassword"
                                    name="confirmePassword"
                                    placeholder={texts.passwordPlaceholder}
                                    value={editUSer.confirmePassword}
                                    onChange={handleInputChange}
                                    disabled={!editMode}
                                />
                                {
                                    showPassword.confirmePassword ?
                                        <BiShow className={"input-eyes"} data-set={"confirmePassword"}
                                                onClick={handleClickEyes}/>
                                        :
                                        <BiHide className={"input-eyes"} data-set={"confirmePassword"}
                                                onClick={handleClickEyes}/>
                                }
                            </div>
                        </>
                    }
                    {
                        editMode && <div className="buttons">
                            <input
                                className={"submit-input"}
                                type="submit"
                                value={texts.buttonValide}
                            />

                            <input
                                className={"reset-input"}
                                type="reset"
                                value={texts.buttonCancel}
                                onClick={handleClickReset}
                            />
                        </div>
                    }
                </form>
            </div>
        );
    }
;

UserAccount.propTypes =
{
    user: PropTypes.shape({
        username: PropTypes.string.isRequired,
        first_name: PropTypes.string.isRequired,
        last_name: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        picture: PropTypes.string,
    }),
    loader: PropTypes.bool.isRequired,
    status: PropTypes.number.isRequired,
    redirectTo: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool
    ]),
    texts: PropTypes.shape({
        spinner: PropTypes.string.isRequired,
        username: PropTypes.string.isRequired,
        usernamePlaceholder: PropTypes.string.isRequired,
        password: PropTypes.string.isRequired,
        passwordPlaceholder: PropTypes.string.isRequired,
        confirmePassword: PropTypes.string.isRequired,
        firstName: PropTypes.string.isRequired,
        firstNamePlaceholder: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        lastNamePlaceholder: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        emailPlaceholder: PropTypes.string.isRequired,
        modalTitle: PropTypes.string.isRequired,
        modalMessage: PropTypes.string.isRequired,
    }).isRequired,

    editUserSubmit: PropTypes.func.isRequired,
    clearUserMessage: PropTypes.func.isRequired,
    deleteUserSubmit: PropTypes.func.isRequired,
}
;

export default UserAccount;