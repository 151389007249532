import { connect } from 'react-redux';
import { navigationActions, userActions } from '../../store/actions';

import Login from '../../components/Login/Login';


const mapStateToProps = ( state ) => ({
    redirectTo: state.navigation.redirectTo,
    loader: state.user.loader,
    status: state.user.status,
    isLogged: state.user.isLogged,
    texts: state.navigation.texts.user,
});

const mapDispatchToProps = (dispatch) => ({
    redirected: () => {
        dispatch(navigationActions.redirected());
    },

    loginSubmit: ( payload ) => {
        dispatch(userActions.loginSubmit( payload ));
    },

    clearUserMessage: () => {
        dispatch(navigationActions.clearUserMessage());
    },
});



export default connect(mapStateToProps, mapDispatchToProps)(Login);

