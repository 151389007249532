export const GET_TAGS_INGREDIENTS_UNITS_STATUS = 'GET_TAGS_INGREDIENTS_UNITS_STATUS';
export const GET_TAGS_INGREDIENTS_UNITS_STATUS_SUCCESS = 'GET_TAGS_INGREDIENTS_UNITS_STATUS_SUCCESS';
export const GET_TAGS_INGREDIENTS_UNITS_STATUS_FAIL = 'GET_TAGS_INGREDIENTS_UNITS_STATUS_FAIL';

export const GET_PUBLIC_RECIPES = 'GET_PUBLIC_RECIPES';
export const GET_PUBLIC_RECIPES_SUCCESS = 'GET_PUBLIC_RECIPES_SUCCESS';
export const GET_PUBLIC_RECIPES_FAIL = 'GET_PUBLIC_RECIPES_FAIL';

export const GET_OWN_RECIPES = 'GET_OWN_RECIPES';
export const GET_OWN_RECIPES_SUCCESS = 'GET_OWN_RECIPES_SUCCESS';
export const GET_OWN_RECIPES_FAIL = 'GET_OWN_RECIPES_FAIL';

export const GET_ONE_RECIPES_BY_SLUG = 'GET_ONE_RECIPES_BY_SLUG';
export const GET_ONE_RECIPES_BY_SLUG_SUCCESS = 'GET_ONE_RECIPES_BY_SLUG_SUCCESS';
export const GET_ONE_RECIPES_BY_SLUG_FAIL = 'GET_ONE_RECIPES_BY_SLUG_FAIL';

export const EDIT_RECIPE_SUBMIT = 'EDIT_RECIPE_SUBMIT';
export const EDIT_RECIPE_SUBMIT_SUCCESS = 'EDIT_RECIPE_SUBMIT_SUCCESS';
export const EDIT_RECIPE_SUBMIT_FAIL = 'EDIT_RECIPE_SUBMIT_FAIL';

export const DELETE_RECIPE_SUBMIT = 'DELETE_RECIPE_SUBMIT';
export const DELETE_RECIPE_SUBMIT_SUCCESS = 'DELETE_RECIPE_SUBMIT_SUCCESS';
export const DELETE_RECIPE_SUBMIT_FAIL = 'DELETE_RECIPE_SUBMIT_FAIL';

export const FILTER_RECIPES = 'FILTER_RECIPES';

export const NEW_RECIPE_SUBMIT = 'NEW_RECIPE_SUBMIT';
export const NEW_RECIPE_SUBMIT_FAIL = 'NEW_RECIPE_SUBMIT_FAIL';





export const getTagsIngredientsUnitsStatus = (payload) => ({
  type: GET_TAGS_INGREDIENTS_UNITS_STATUS,
  payload,
});

export const getTagsIngredientsUnitsStatusSuccess = (payload) => ({
  type: GET_TAGS_INGREDIENTS_UNITS_STATUS_SUCCESS,
  payload,
});

export const getTagsIngredientsUnitsStatusFail = (payload) => ({
  type: GET_TAGS_INGREDIENTS_UNITS_STATUS_FAIL,
  payload,
});

export const getPublicRecipes = (payload) => ({
  type: GET_PUBLIC_RECIPES,
  payload,
});

export const getPublicRecipesSuccess = (payload) => ({
  type: GET_PUBLIC_RECIPES_SUCCESS,
  payload,
});

export const getPublicRecipesFail = (payload) => ({
  type: GET_PUBLIC_RECIPES_FAIL,
  payload,
});

export const getOwnRecipes = (payload) => ({
  type: GET_OWN_RECIPES,
  payload,
});

export const getOwnRecipesSuccess = (payload) => ({
  type: GET_OWN_RECIPES_SUCCESS,
  payload,
});

export const getOwnRecipesFail = (payload) => ({
  type: GET_OWN_RECIPES_FAIL,
  payload,
});

export const getOneRecipesBySlug = (payload) => ({
  type: GET_ONE_RECIPES_BY_SLUG,
  payload,
});

export const getOneRecipesBySlugSuccess = (payload) => ({
  type: GET_ONE_RECIPES_BY_SLUG_SUCCESS,
  payload,
});

export const getOneRecipesBySlugFail = (payload) => ({
  type: GET_ONE_RECIPES_BY_SLUG_FAIL,
  payload,
});

export const editRecipeSubmit = (payload , payload2, payload3, payload4) => ({
  type: EDIT_RECIPE_SUBMIT,
  payload,
  payload2,
  payload3,
  payload4,
});

export const editRecipeSubmitSuccess = (payload) => ({
  type: EDIT_RECIPE_SUBMIT_SUCCESS,
  payload,
});

export const editRecipeSubmitFail = (payload) => ({
  type: EDIT_RECIPE_SUBMIT_FAIL,
  payload,
});

export const deleteRecipeSubmit = ( payload ) => ({
  type: DELETE_RECIPE_SUBMIT,
  payload,
});

export const deleteRecipeSubmitSuccess = (payload) => ({
  type: DELETE_RECIPE_SUBMIT_SUCCESS,
  payload,
});

export const deleteRecipeSubmitFail = (payload) => ({
  type: DELETE_RECIPE_SUBMIT_FAIL,
  payload,
});

export const filterRecipes = ( payload ) => ({
  type: FILTER_RECIPES,
  payload,
});

export const newRecipeSubmit = ( payload, payload2 ) => ({
  type: NEW_RECIPE_SUBMIT,
  payload,
  payload2,
});

export const newRecipeSubmitFail = (payload) => ({
  type: NEW_RECIPE_SUBMIT_FAIL,
  payload,
});

