/**
 * Import modules
 */
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';

/**
 * Import components
 */
import App from './container/App/App';

/**
 * Import locals
 */
import './assets/scss/_reset.scss';
import './assets/scss/_variables.scss';
import './assets/scss/index.scss';
import reportWebVitals from './reportWebVitals';
import store from './store';

const Root = () => (
    <Provider store={store}>
        <Router>
            <React.StrictMode>
                <App />
            </React.StrictMode>
        </Router>
    </Provider>
)



ReactDOM.render( <Root />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
