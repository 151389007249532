/**
 * Import locals
 */
import {
    UPLOAD_FILE,
    UPLOAD_FILE_SUCCESS,
    UPLOAD_FILE_FAIL,
    SET_EDIT_MODE
} from "../actions/filesActions";

const INITIAL_STATE = {
    Loading: false,
    editMode: false
};

const userReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case SET_EDIT_MODE:
            return {
                ...state,
                editMode: !state.editMode

            };

        case UPLOAD_FILE:
            return {
                ...state,
                loader: true
            };

        case UPLOAD_FILE_SUCCESS:
            return {
                ...state,
                loader: false
            };

        case UPLOAD_FILE_FAIL:
            return {
                ...state,
                loader: false
            };



        default:
            return state;
    }
};

export default userReducer;