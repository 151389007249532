import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

import './ConfirmeEmail.scss'
import {Spinner} from "react-bootstrap";


const ConfirmeEmail =
    ({
         token,
         texts,
         loader,
         showLogin,

         //FUNCTION
         confirmeMailSubmit,
     }) => {

        useEffect(() => {
            confirmeMailSubmit(token)
        }, [confirmeMailSubmit, token])

        return (
            <div className='ConfirmeEmail h-100'>
                <h1 className={"welcome-title"}>{ texts.title }</h1>

                <main className="welcome-content d-flex flex-column justify-content-lg-evenly">
                    <div className="welcome-message">
                        {
                            loader &&
                            <Spinner animation="border" role="status">
                                <span className="sr-only">{texts.spinner}</span>
                            </Spinner>
                        }
                        {
                            showLogin &&
                            <>
                                <p className="successfulRegister">
                                    {texts.successMessage}
                                </p>
                                <Link
                                    to={`/login`}
                                    key={`login-link`}
                                    className="login-link"
                                >
                                    {texts.loginLinkValue}
                                </Link>
                            </>
                        }
                    </div>

                </main>

            </div>
        );
    };

ConfirmeEmail.propTypes = {
    text404: PropTypes.arrayOf(PropTypes.string),
    redirectTo: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool
    ]),
    texts: PropTypes.shape({
        title: PropTypes.string.isRequired,
        spinner: PropTypes.string.isRequired,
        successMessage: PropTypes.string.isRequired,
        loginLinkValue: PropTypes.string.isRequired,
    }).isRequired,
    showLogin: PropTypes.bool.isRequired,

    redirected: PropTypes.func,
    confirmeMailSubmit: PropTypes.func.isRequired,
};

export default ConfirmeEmail;