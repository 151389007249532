/**
 * Import Modules
 */
import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types';
import {BiShow, BiHide} from 'react-icons/bi';
import {Redirect} from 'react-router-dom';

/**
 * import components
 */


/**
 * Import Locals
 */
import './Login.scss'
import {Link} from "react-router-dom";
import {Spinner} from "react-bootstrap";

const Login =
    ({
         loader,
         isLogged,
         texts,

         //func
         loginSubmit,
         clearUserMessage,
     }) => {

        const [user, setUser] = useState({
            email: '',
            password: '',
        })

        const [showPassword, setShowPassword] = useState({
            password: false,
        })

        const handleClickEyes = (evt) => {

            let dataset = evt.target.dataset.set

            if (evt.target.tagName === 'path') {
                dataset = evt.target.parentNode.dataset.set
            }
            setShowPassword({...showPassword, [dataset]: !showPassword[dataset]})

        }

        const formReset = () => {
            setUser({
                email: '',
                password: '',
            })
        }


        const handleInputChange = (evt) => {
            setUser({...user, [evt.target.id]: evt.target.value})
        }

        const handleSubmit = (evt) => {
            evt.preventDefault()
            loginSubmit(user)
        }

        const handleClickReset = _ => {
            formReset()
        }

        useEffect(() => {
            clearUserMessage()
        }, [clearUserMessage])

        return (
            <div className={"Login"}>
                {
                    isLogged ? <Redirect to={"/recipes"}/> : <></>
                }
                <h1 className="login-title">
                    { texts.loginTitle }
                </h1>
                {
                    loader &&
                        <Spinner animation="border" role="status">
                            <span className="sr-only">{ texts.spinner }</span>
                        </Spinner>
                }
                <form
                    className="login-form"
                    onSubmit={handleSubmit}
                >
                    <div className="email-container formInput-container">
                        <label htmlFor="email">{ texts.email }</label>
                        <input
                            className={"email-input"}
                            type="text"
                            id="email"
                            name="email"
                            placeholder={ texts.emailPlaceholder }
                            value={user.email}
                            onChange={handleInputChange}
                        />
                    </div>

                    <div className="password-container formInput-container">
                        <label htmlFor="password">{ texts.password }</label>
                        <input
                            className={"password-input"}
                            type={showPassword.password ? 'text' : 'password'}
                            id="password"
                            name="password"
                            placeholder={ texts.passwordPlaceholder }
                            value={user.password}
                            onChange={handleInputChange}
                        />
                        {
                            showPassword ?
                                <BiHide className={"input-eyes"} data-set={"password"} onClick={handleClickEyes}/> :
                                <BiShow className={"input-eyes"} data-set={"password"} onClick={handleClickEyes}/>
                        }

                    </div>


                    <div className="buttons">
                        <input
                            className={"submit-input"}
                            type="submit"
                            value={ texts.buttonValide }
                        />

                        <input
                            type="reset"
                            value={ texts.buttonCancel }
                            onClick={handleClickReset}
                        />
                    </div>

                </form>
                <p className={"register-message"}>
                    { texts.registerMessage1 }
                    <Link
                        to={`/register`}
                        key={`register-link`}
                        className="register-link"
                    >
                        { texts.link1 }
                    </Link>
                </p>

                <p className={"register-message"}>
                    { texts.registerMessage2 }
                    <Link
                        to={`/ask-password-reset`}
                        key={`ask-password-reset`}
                        className="ask-password-reset"
                    >
                        { texts.link2 }
                    </Link>
                </p>

            </div>
        );
    };

Login.propTypes = {
    loader: PropTypes.bool.isRequired,
    isLogged: PropTypes.bool.isRequired,
    texts: PropTypes.shape({
        title: PropTypes.string.isRequired,
        spinner: PropTypes.string.isRequired,
        username: PropTypes.string.isRequired,
        usernamePlaceholder: PropTypes.string.isRequired,
        password: PropTypes.string.isRequired,
        passwordPlaceholder: PropTypes.string.isRequired,
        confirmePassword: PropTypes.string.isRequired,
        firstName: PropTypes.string.isRequired,
        firstNamePlaceholder: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        lastNamePlaceholder: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        emailPlaceholder: PropTypes.string.isRequired,
        registerMessage1: PropTypes.string.isRequired,
        link1: PropTypes.string.isRequired,
        registerMessage2: PropTypes.string.isRequired,
        link2: PropTypes.string.isRequired,
    }).isRequired,

    loginSubmit: PropTypes.func.isRequired,
    clearUserMessage: PropTypes.func.isRequired,
};

export default Login;