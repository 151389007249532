export const REDIRECT = 'REDIRECT';
export const REDIRECTED = 'REDIRECTED';
export const CLEAR_USER_MESSAGE = 'CLEAR_USER_MESSAGE';

export const SET_LANGUAGE = 'SET_LANGUAGE';



export const redirect = (payload) => ({
  type: REDIRECT,
  payload,
});

export const redirected = () => ({
  type: REDIRECTED,
});

export const clearUserMessage = () => ({
  type: CLEAR_USER_MESSAGE,
});

export const setLanguage = ( payload ) => ({
  type: SET_LANGUAGE,
  payload,
});

