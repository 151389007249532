
import {
  navigationActions,
  recipeActions,
  userActions,
  filesActions
} from '../actions';


const INITIAL_STATE = {
  recipes: [],
  details:{
    tags: [],
    units: [],
    ingredients: [],
    status: []
  },
  loader: false,
  recipe: {},
  status: '',
  message: '',
  searchOptions:{
    tagsCheck: {},
    inputValue:'',
  },
  filteredRecipes: [],
};

const reducer = (state = INITIAL_STATE, action)  => {
  switch (action.type) {
    case userActions.LOGOUT_SUCCESS:
      return {
        ...state,
        loader: false,
        message: '',
        status: '',
        recipes: [],
        recipe: {},
        filteredRecipes: [],
      };

    case recipeActions.GET_TAGS_INGREDIENTS_UNITS_STATUS:
      return {
        ...state,
        loader: true,
        message: '',
        status: ''
      };

    case recipeActions.GET_TAGS_INGREDIENTS_UNITS_STATUS_SUCCESS:
      return {
        ...state,
        details: { ...action.payload.data },
        loader: false
      };

    case recipeActions.GET_TAGS_INGREDIENTS_UNITS_STATUS_FAIL:
      return {
        ...state,
        loader: false
      };

    case recipeActions.GET_PUBLIC_RECIPES:
      return {
        ...state,
        loader: true,
        message: '',
        status: ''
      };

    case recipeActions.GET_PUBLIC_RECIPES_SUCCESS:
      return {
        ...state,
        recipes: [ ...action.payload.data ],
        loader: false,
        filteredRecipes: [ ...action.payload.data ],
      };

    case recipeActions.GET_PUBLIC_RECIPES_FAIL:
      return {
        ...state,
        loader: false
      };

    case recipeActions.GET_OWN_RECIPES:
      return {
        ...state,
        loader: true,
        message: '',
        status: ''
      };

    case recipeActions.GET_OWN_RECIPES_SUCCESS:
      return {
        ...state,
        recipes: [ ...action.payload.data ],
        loader: false,
        filteredRecipes: [ ...action.payload.data ],
      };

    case recipeActions.GET_OWN_RECIPES_FAIL:
      return {
        ...state,
        loader: false
      };

    case recipeActions.GET_ONE_RECIPES_BY_SLUG:
      return {
        ...state,
        loader: true,
        message: '',
        status: '',
        recipe: {}
      };

    case recipeActions.GET_ONE_RECIPES_BY_SLUG_SUCCESS:
      return {
        ...state,
        status: action.payload.status,
        recipe: {...action.payload.data},
        loader: false
      };

    case recipeActions.GET_ONE_RECIPES_BY_SLUG_FAIL:
      return {
        ...state,
        ...action.payload,
        loader: false
      };

    case recipeActions.EDIT_RECIPE_SUBMIT:
      return {
        ...state,
        loader: true,
        message: '',
        status: ''
      };

    case recipeActions.EDIT_RECIPE_SUBMIT_SUCCESS:
      return {
        ...state,
        status: action.payload.status,
        recipe: {...action.payload},
        loader: false
      };

    case recipeActions.EDIT_RECIPE_SUBMIT_FAIL:
      return {
        ...state,
        ...action.payload,
        loader: false
      };

    case navigationActions.CLEAR_USER_MESSAGE:
      return {
        ...state,
        message: '',
        status: ''
      };

    case recipeActions.DELETE_RECIPE_SUBMIT:
      return {
        ...state,
        loader: true,
        message: '',
        status: ''
      };

    case recipeActions.DELETE_RECIPE_SUBMIT_SUCCESS:
      return {
        ...state,
        status: action.payload.status,
        message: action.payload.message,
        recipe: {...action.payload},
        loader: false
      };

    case recipeActions.DELETE_RECIPE_SUBMIT_FAIL:
      return {
        ...state,
        ...action.payload,
        loader: false
      };

    case recipeActions.FILTER_RECIPES:
      return {
        ...state,
        filteredRecipes: [...action.payload],
        loader: false
      };

    case recipeActions.NEW_RECIPE_SUBMIT:
      return {
        ...state,
        loader: true,
        message: '',
        status: ''
      };

    case recipeActions.NEW_RECIPE_SUBMIT_FAIL:
      return {
        ...state,
        ...action.payload,
        loader: false
      };

    case filesActions.UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        recipe: { ...state.recipe, pictures: action.payload.pictures}
      };

    case filesActions.DELETE_FILE_SUCCESS:
      return {
        ...state,
        recipe: { ...state.recipe, pictures: action.payload.pictures}
      };

    default:
      return state;
  }
};

export default reducer;