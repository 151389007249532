import { connect } from 'react-redux';
import {navigationActions, recipeActions} from '../../store/actions';

import AddNewRecipe from '../../components/AddNewRecipe/AddNewRecipe';


const mapStateToProps = ( state ) => ({
    redirectTo: state.navigation.redirectTo,
    userId: state.user.user.id || state.user.user.oauth_id,
    userName: state.user.user.username,
    tags: state.recipes.details.tags,
    units: state.recipes.details.units,
    ingredientsList: state.recipes.details.ingredients,
    texts: state.navigation.texts.recipe,
});

const mapDispatchToProps = (dispatch) => ({
    redirected: () => {
        dispatch(navigationActions.redirected());
    },
    newRecipeSubmit: ( payload, payload2 ) => {
        dispatch(recipeActions.newRecipeSubmit( payload, payload2 ));
    },
});



export default connect(mapStateToProps, mapDispatchToProps)(AddNewRecipe);

