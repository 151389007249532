import { connect } from 'react-redux';
import {navigationActions, userActions} from '../../store/actions';

import UserAccount from '../../components/UserAccount/UserAccount';


const mapStateToProps = ( state ) => ({
    redirectTo: state.navigation.redirectTo,
    user: state.user.user,
    loader: state.user.loader,
    status: state.user.status,
    texts: state.navigation.texts.user,
});

const mapDispatchToProps = (dispatch) => ({
    redirected: () => {
        dispatch(navigationActions.redirected());
    },
    editUserSubmit: ( user ) => {
        dispatch(userActions.editUserSubmit( user ));
    },
    deleteUserSubmit: ( payload ) => {
        dispatch(userActions.deleteUserSubmit( payload ));
    },

    clearUserMessage: () => {
        dispatch(navigationActions.clearUserMessage());
    },
});



export default connect(mapStateToProps, mapDispatchToProps)(UserAccount);

