/**
 * Import Modules
 */
import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types';
import {useHistory} from "react-router-dom";
import ReactTooltip from "react-tooltip";

/**
 * import components
 */


/**
 * Import Locals
 */
import './SearchBar.scss'


const SearchBar =
    ({
         recipes,
         tags,
         texts,

        //func
         filterRecipes,
     }) => {

        const history = useHistory();

        const [tagsCheck, setTagsCheck] = useState({});

        const [inputValue, setInputValue] = useState('')

        const [showOptions, setShowOptions] = useState(false)

        useEffect(() => {
            const initializeTagCheck = {}
            for (const tag of tags) {
                initializeTagCheck[tag.id] = true
            }
            setTagsCheck({...initializeTagCheck})
        }, [tags])

        useEffect(() => {
            const validTag = Object.keys(tagsCheck)
                .filter((key) => tagsCheck[key] === true)
                .map((el) => parseInt(el))

            const tagsFilteredRecipes = recipes.filter((recipe) => validTag.includes(recipe.tag_id))

            const nameFilteredRecipes = tagsFilteredRecipes.filter((recipe) => recipe.recipe_name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()
                .includes(inputValue.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()
                ))

            /**
             * add send to reducer for global store filtered recipes
             */
            filterRecipes( nameFilteredRecipes )
        }, [ inputValue, tagsCheck ])


        const handleInputChange = (evt) => {
            setInputValue(evt.target.value)
        }

        const handleCheckboxChange = (evt) => {
            setTagsCheck({...tagsCheck, [evt.target.value]: evt.target.checked})
        }

        const handleShowOptions = () => {
            setShowOptions(!showOptions)
        }

        const cssName = showOptions ? 'option-show' : 'options-hide'

        const handleClickSearch = _ => {
            history.push("/recipes");
        }

        const handleSubmitSearch = evt => {
            if (evt.key === 'Enter') {
                evt.preventDefault()
                history.push("/recipes");
            }
        }

        return (
            <div className={"SearchBar"}>
                <div
                    className="input-container"
                    data-tip={texts.tooltip}
                >
                    <input
                        className={"searchBar-input"}
                        type="search"
                        id="name"
                        name="name"
                        placeholder={texts.placeholder}
                        value={inputValue}
                        onChange={handleInputChange}
                        onKeyDown={handleSubmitSearch}
                    />

                    <img
                        src={process.env.PUBLIC_URL + '/three-dots-vertical.svg'}
                        alt={"open options"}
                        className={"options-button"}
                        onClick={handleShowOptions}
                    />

                    <div
                        className={`options ${cssName}`}
                    >
                        {
                            showOptions &&
                            <ul className={`option-checkbox-container ${cssName}`}>
                                {
                                    tags.map((tag) => (
                                        <li
                                            key={`${tag.id}_${tag.name}`}
                                            className={"option-tags-checkbox"}
                                        >
                                            <label
                                                className={"search-label"}
                                                key={`${tag.id}_label`}
                                                htmlFor={tag.name}
                                            >
                                                {tag.name}
                                            </label>
                                            <input
                                                className={"search-input"}
                                                key={`${tag.id}_input`}
                                                type="checkbox"
                                                id={tag.id}
                                                name={tag.name}
                                                value={tag.id}
                                                data-category="tag"
                                                onChange={handleCheckboxChange}
                                                defaultChecked={tagsCheck[tag.id]}
                                            />
                                        </li>
                                    ))
                                }
                            </ul>
                        }

                    </div>

                    <img
                        src={process.env.PUBLIC_URL + '/search.svg'}
                        alt={"recipe search"}
                        className={"searchButton"}
                        onClick={handleClickSearch}
                    />

                </div>
                <ReactTooltip/>
            </div>
        );
    };

SearchBar.propTypes = {
    recipes: PropTypes.arrayOf(PropTypes.shape({
        recipe_id: PropTypes.number.isRequired,
        recipe_name: PropTypes.string.isRequired,
        recipe_slug: PropTypes.string.isRequired,
        recipe_description: PropTypes.string.isRequired,
        author_name: PropTypes.string.isRequired,
        recipe_preparation_time: PropTypes.number.isRequired,
        recipe_cooking_time: PropTypes.number.isRequired,
        part_number: PropTypes.number.isRequired,
    })),
    tags: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired
    })),
    texts: PropTypes.shape({
        tooltip: PropTypes.string.isRequired,
        placeholder: PropTypes.string.isRequired,
    }),

    filterRecipes: PropTypes.func.isRequired,
};

export default SearchBar;