/**
 * Import modules
 */
import React, {useState, useEffect} from "react";
import Resizer from "react-image-file-resizer";

/**
 * Import Locals
 */
import './UploadPictures.scss';

/**
 * Create and setup file reader
 * @type {FileReader}
 */
const reader = new FileReader();
const resizeFile = (file, width) =>
    new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            width,
            width,
            "JPG",
            100,
            0,
            (uri) => {
                resolve(uri);
            },
            "blob"
        );
    });


const UploadPictures = ({
    recipeId,
    recipeSlug,
    texts,

    //func
    uploadFile,

}) => {

    const [ fileInput, setFileInput ] = useState( {})
    const [ selectMessage, setSelectMessage ] = useState( texts.defaultSelectMessage)

    /**
     * Reset select message on input change
     */
    useEffect(() => {
        setSelectMessage( texts.defaultSelectMessage )
    },[fileInput])

    /**
     * Handeling input change
     * @param event
     * @return {Promise<void>}
     */
    const inputChange = async ( event ) => {
        const file = event.target.files[0]
        let image = null

        /**
         * Check if file on request
         */
        if ( !file ) {
            setFileInput({});
        } else {

            /**
             * Check if it is an image
             * @type {string[]}
             */
            const allowedMime = ['image/jpg', 'image/jpeg', 'image/png', 'image/svg+xml']

            if (!allowedMime.includes(file.type)) {

                setFileInput({})
                await setSelectMessage( texts.errorSelectMessage )
                return
            }

            /**
             * Resize file
             * @type {unknown}
             */
            image = await resizeFile(file,400);
            image.name = 'img-400'

            reader.onloadend = () => {
                setFileInput({
                    file: image,
                    imagePreviewUrl: reader.result
                });
            }
            reader.readAsDataURL(image);
        }
    }

    /**
     * Handeling upload
     * @param event
     */
    const onFileUpload = ( event ) => {
        event.preventDefault()

        // Create an object of formData
        const formData = new FormData();

        formData.append(
            "picture",
            fileInput.file,
            fileInput.file.name
        );

        formData.append( "recipeId", recipeId )

        const pictureToSend = {
            pictures: fileInput.file,
            recipeId
        }

        // Request made to the backend api
        uploadFile(formData, recipeSlug)
    };

    /**
     * Handeling cancel
     * @param event
     */
    const onFileCancel = ( event) => {
        setFileInput({})
    };

    /**
     * Create preview tag
     */
    let {imagePreviewUrl} = fileInput;
    let imagePreview = null;
    if (imagePreviewUrl) {
        imagePreview = (<img className={"preview-img"} src={imagePreviewUrl} alt="img preview"/>);
    } else {
        imagePreview = (<div className="previewText">{ selectMessage }</div>);
    }

    useEffect(() => {
        if (imagePreviewUrl) {
            imagePreview = (<img className={"preview-img"} src={imagePreviewUrl} alt="img preview"/>);
        } else {
            imagePreview = (<div className="previewText">{ selectMessage }</div>);
        }
    },[selectMessage])

    return (
        <div className="UploadPictures">
            <form
                action='.'
                encType="multipart/form-data"
                onReset={ onFileCancel }
                onSubmit={ onFileUpload }
            >
                <input type="file" name="picture" id="picture" onChange={ inputChange } />
                {
                    fileInput.file &&
                    <div className="form_button">
                        <button type="reset">
                            Cancel
                        </button>

                        <button type="submit">
                            Upload!
                        </button>


                    </div>
                }

            </form>
            <div className="imgPreview">
                {imagePreview}
            </div>
        </div>
    )
}

export default UploadPictures;