/**
 * Import Modules
 */
import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types';
import {BiShow, BiHide} from 'react-icons/bi';

/**
 * import components
 */


/**
 * Import Locals
 */
import './PasswordReset.scss'
import {Link} from "react-router-dom";
import {Spinner} from "react-bootstrap";


const PasswordReset =
    ({
         token,
         userId,
         loader,
         message,
         status,

         // func
         passwordResetSubmit,
         clearUserMessage,
     }) => {

        const [newPassword, setNewPassword] = useState({
            password: '',
            confirmePassword: '',
            token,
            userId
        })

        const [showPassword, setShowPassword] = useState({
            password: false,
            confirmePassword: false,
        })

        const handleClickEyes = (evt) => {

            let dataset = evt.target.dataset.set

            if (evt.target.tagName === 'path') {
                dataset = evt.target.parentNode.dataset.set
            }
            setShowPassword({...showPassword, [dataset]: !showPassword[dataset]})

        }

        const formReset = () => {
            setNewPassword({
                password: '',
                confirmePassword: '',
            })
        }


        const handleInputChange = (evt) => {
            setNewPassword({...newPassword, [evt.target.id]: evt.target.value})
        }

        const handleSubmit = (evt) => {
            evt.preventDefault()

            if (newPassword.password === newPassword.confirmePassword && newPassword.password !== '') {

                console.log('new user', newPassword)
                passwordResetSubmit(newPassword)
            }

        }

        const handleClickReset = _ => {
            formReset()
        }

        useEffect(() => {
            clearUserMessage()
        }, [clearUserMessage])

        let cssMessage = ''
        if ( !isNaN(parseInt(status)) && status !== '') {
            cssMessage = status === 200 ? 'alert-success' : 'alert-danger'
        }

        return (
            <div className={"PasswordReset"}>
                <h1 className="PasswordReset-title">
                    Reset your password
                </h1>
                <div className={`message ${cssMessage}`}>
                    {message}
                </div>
                {
                    loader &&
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>

                }
                <form
                    className="PasswordReset-form"
                    onSubmit={handleSubmit}
                >
                    <div className="password-container formInput-container">
                        <label htmlFor="password">Password: </label>
                        <input
                            className={"password-input"}
                            type={showPassword.password ? 'text' : 'password'}
                            id="password"
                            name="password"
                            placeholder={"your password"}
                            value={newPassword.password}
                            onChange={handleInputChange}
                        />
                        {
                            showPassword ?
                                <BiHide className={"input-eyes"} data-set={"password"} onClick={handleClickEyes}/> :
                                <BiShow className={"input-eyes"} data-set={"password"} onClick={handleClickEyes}/>
                        }

                    </div>

                    <div className="confirmePassword-container formInput-container">
                        <label htmlFor="confirmePassword">Confirme password: </label>
                        <input
                            className={"confirmePassword-input"}
                            type={showPassword.confirmePassword ? 'text' : 'password'}
                            id="confirmePassword"
                            name="confirmePassword"
                            placeholder={"your Password"}
                            value={newPassword.confirmePassword}
                            onChange={handleInputChange}
                        />
                        {
                            showPassword ? <BiHide className={"input-eyes"} data-set={"confirmePassword"}
                                                   onClick={handleClickEyes}/> :
                                <BiShow className={"input-eyes"} data-set={"confirmePassword"}
                                        onClick={handleClickEyes}/>
                        }
                    </div>


                    <div className="buttons">
                        <input
                            className={"submit-input"}
                            type="submit"
                            value={"Submit"}
                        />

                        <input
                            type="reset"
                            value="Reset"
                            onClick={handleClickReset}
                        />
                    </div>

                </form>
                <p className={"register-message"}>
                    {
                        `If you havent account you can create it `
                    }
                    <Link
                        to={`/register`}
                        key={`register-link`}
                        className="register-link"
                    >
                        here
                    </Link>
                </p>


            </div>
        );
    };

PasswordReset.propTypes = {
    token: PropTypes.string.isRequired,
    userId: PropTypes.number.isRequired,
    loader: PropTypes.bool.isRequired,
    message: PropTypes.string.isRequired,
    status: PropTypes.number.isRequired,

    passwordResetSubmit: PropTypes.func.isRequired,
    clearUserMessage: PropTypes.func.isRequired,
};

export default PasswordReset;