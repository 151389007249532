/**
 * Import modules
 */
import React, {useState, useEffect} from "react";
import {BiCheck, BiTrash, BiX} from "react-icons/bi";

/**
 * Import locals
 */
import './PicturesList.scss';



const PicturesList = ({
    recipe,
    texts,

    // func
    deleteFile,
}) => {

    const [ picturesList, setPicturesList] = useState(recipe.pictures.map((img) => {
        return {
            location: img,
            name: img
                .replace(`files/`,'')
                .replace(`${recipe.author_id}/${recipe.recipe_id}/`,'')
                .slice('.', -4),
            editMode:false
        }
    }))

    /**
     * Handeling click on trash icon or cancel
     * @param event
     */
    const handleClickTrash = ( event ) => {
        let pictureIndex = null
        if ( event.target.localName === 'svg' ) {
            pictureIndex = event.target.id
        } else if ( event.target.localName === 'path' ) {
            pictureIndex = event.target.parentNode.id
        }
        const editedPicture = {...picturesList[pictureIndex], editMode:!picturesList[pictureIndex].editMode}
        const editedList = [...picturesList]
        editedList[pictureIndex] = editedPicture

        setPicturesList([ ...editedList])


    }

    /**
     * Handeling click on confirme ( send delete call )
     * @param event
     */
    const handleConfirmeDelete = ( event ) => {
        let pictureIndex = null

        if ( event.target.localName === 'svg' ) {
            pictureIndex = event.target.id
        } else if ( event.target.localName === 'path' ) {
            pictureIndex = event.target.parentNode.id
        }

        deleteFile(
            {
                pictureName: picturesList[pictureIndex].location,
                recipeId: recipe.recipe_id
            },
            recipe.recipe_slug
        )
    }


    let test = picturesList.map((img, i) => (
        <div key={img.name} className={'img-item'}>
            <img className={"image-preview"} src={window.location.origin+ '/' +img.location} alt={img.name}/>
            {
                img.editMode ?
                    <>
                        <div className={"name-confirm"}>{texts.deletionConfirm}</div>
                        <BiCheck onClick={ handleConfirmeDelete } className={"button-2"} id={i} />
                        <BiX className={"button-1"} onClick={handleClickTrash} id={i} />
                    </>
                    :
                    <>
                        <legend>{img.name}</legend>
                        {
                            img.name === 'cooking-pot' ?
                                <></>
                                :
                                <BiTrash className={"button-1"} onClick={handleClickTrash} id={i} />
                        }
                    </>

            }
        </div>
    ))

    useEffect(() => {
        test = picturesList.map((img, i) => (
            <div key={img.name} className={'img-item'}>
                <img className={"image-preview"} src={window.location.origin+ '/' +img.location} alt={img.name}/>
                {
                    img.editMode ?
                        <>
                            <div className={"name-confirm"}>{texts.deletionConfirm}</div>
                            <BiCheck onClick={ handleConfirmeDelete } className={"button-2"} id={i} />
                            <BiX className={"button-1"} onClick={handleClickTrash} id={i} />
                        </>
                        :
                        <>
                            <legend>{img.name}</legend>
                            <BiTrash className={"button-1"} onClick={handleClickTrash} id={i} />
                        </>

                }
            </div>
        ))
    }, [recipe.pictures])

    return (
        <div className="PicturesList">

            <ul className="img-list">

                {
                    test
                }
            </ul>

        </div>
    )
}

export default PicturesList;