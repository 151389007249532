export const UPLOAD_FILE = 'UPLOAD_FILE';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_FAIL = 'UPLOAD_FILE_FAIL';

export const SET_EDIT_MODE = 'SET_EDIT_MODE'

export const DELETE_FILE = 'DELETE_FILE';
export const DELETE_FILE_SUCCESS = 'DELETE_FILE_SUCCESS';
export const DELETE_FILE_FAIL = 'DELETE_FILE_FAIL';



export const uploadFile = (payload, payload2) => ({
    type: UPLOAD_FILE,
    payload,
    payload2
});

export const uploadFile_success = (payload) => ({
    type: UPLOAD_FILE_SUCCESS,
    payload
});

export const uploadFile_fail = (payload) => ({
    type: UPLOAD_FILE_FAIL,
    payload
});

export const set_edit_mode = () => ({
    type: SET_EDIT_MODE

});

export const deleteFile = (payload, payload2) => ({
    type: DELETE_FILE,
    payload, payload2
});

export const deleteFile_success = (payload) => ({
    type: DELETE_FILE_SUCCESS,
    payload
});

export const deleteFile_fail = (payload) => ({
    type: DELETE_FILE_FAIL,
    payload
});


